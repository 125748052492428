// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file ssoready/v1/ssoready.proto (package ssoready.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Empty, Message, proto3, Struct, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from enum ssoready.v1.SAMLFlowStatus
 */
export enum SAMLFlowStatus {
  /**
   * @generated from enum value: SAML_FLOW_STATUS_UNSPECIFIED = 0;
   */
  SAML_FLOW_STATUS_UNSPECIFIED = 0,

  /**
   * @generated from enum value: SAML_FLOW_STATUS_IN_PROGRESS = 1;
   */
  SAML_FLOW_STATUS_IN_PROGRESS = 1,

  /**
   * @generated from enum value: SAML_FLOW_STATUS_FAILED = 2;
   */
  SAML_FLOW_STATUS_FAILED = 2,

  /**
   * @generated from enum value: SAML_FLOW_STATUS_SUCCEEDED = 3;
   */
  SAML_FLOW_STATUS_SUCCEEDED = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(SAMLFlowStatus)
proto3.util.setEnumType(SAMLFlowStatus, "ssoready.v1.SAMLFlowStatus", [
  { no: 0, name: "SAML_FLOW_STATUS_UNSPECIFIED" },
  { no: 1, name: "SAML_FLOW_STATUS_IN_PROGRESS" },
  { no: 2, name: "SAML_FLOW_STATUS_FAILED" },
  { no: 3, name: "SAML_FLOW_STATUS_SUCCEEDED" },
]);

/**
 * @generated from enum ssoready.v1.SCIMRequestHTTPMethod
 */
export enum SCIMRequestHTTPMethod {
  /**
   * @generated from enum value: SCIM_REQUEST_HTTP_METHOD_UNSPECIFIED = 0;
   */
  SCIM_REQUEST_HTTP_METHOD_UNSPECIFIED = 0,

  /**
   * @generated from enum value: SCIM_REQUEST_HTTP_METHOD_GET = 1;
   */
  SCIM_REQUEST_HTTP_METHOD_GET = 1,

  /**
   * @generated from enum value: SCIM_REQUEST_HTTP_METHOD_POST = 2;
   */
  SCIM_REQUEST_HTTP_METHOD_POST = 2,

  /**
   * @generated from enum value: SCIM_REQUEST_HTTP_METHOD_PUT = 3;
   */
  SCIM_REQUEST_HTTP_METHOD_PUT = 3,

  /**
   * @generated from enum value: SCIM_REQUEST_HTTP_METHOD_PATCH = 4;
   */
  SCIM_REQUEST_HTTP_METHOD_PATCH = 4,

  /**
   * @generated from enum value: SCIM_REQUEST_HTTP_METHOD_DELETE = 5;
   */
  SCIM_REQUEST_HTTP_METHOD_DELETE = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(SCIMRequestHTTPMethod)
proto3.util.setEnumType(SCIMRequestHTTPMethod, "ssoready.v1.SCIMRequestHTTPMethod", [
  { no: 0, name: "SCIM_REQUEST_HTTP_METHOD_UNSPECIFIED" },
  { no: 1, name: "SCIM_REQUEST_HTTP_METHOD_GET" },
  { no: 2, name: "SCIM_REQUEST_HTTP_METHOD_POST" },
  { no: 3, name: "SCIM_REQUEST_HTTP_METHOD_PUT" },
  { no: 4, name: "SCIM_REQUEST_HTTP_METHOD_PATCH" },
  { no: 5, name: "SCIM_REQUEST_HTTP_METHOD_DELETE" },
]);

/**
 * @generated from enum ssoready.v1.SCIMRequestHTTPStatus
 */
export enum SCIMRequestHTTPStatus {
  /**
   * @generated from enum value: SCIM_REQUEST_HTTP_STATUS_UNSPECIFIED = 0;
   */
  SCIM_REQUEST_HTTP_STATUS_UNSPECIFIED = 0,

  /**
   * @generated from enum value: SCIM_REQUEST_HTTP_STATUS_200 = 1;
   */
  SCIM_REQUEST_HTTP_STATUS_200 = 1,

  /**
   * @generated from enum value: SCIM_REQUEST_HTTP_STATUS_201 = 2;
   */
  SCIM_REQUEST_HTTP_STATUS_201 = 2,

  /**
   * @generated from enum value: SCIM_REQUEST_HTTP_STATUS_204 = 3;
   */
  SCIM_REQUEST_HTTP_STATUS_204 = 3,

  /**
   * @generated from enum value: SCIM_REQUEST_HTTP_STATUS_400 = 4;
   */
  SCIM_REQUEST_HTTP_STATUS_400 = 4,

  /**
   * @generated from enum value: SCIM_REQUEST_HTTP_STATUS_401 = 5;
   */
  SCIM_REQUEST_HTTP_STATUS_401 = 5,

  /**
   * @generated from enum value: SCIM_REQUEST_HTTP_STATUS_404 = 6;
   */
  SCIM_REQUEST_HTTP_STATUS_404 = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(SCIMRequestHTTPStatus)
proto3.util.setEnumType(SCIMRequestHTTPStatus, "ssoready.v1.SCIMRequestHTTPStatus", [
  { no: 0, name: "SCIM_REQUEST_HTTP_STATUS_UNSPECIFIED" },
  { no: 1, name: "SCIM_REQUEST_HTTP_STATUS_200" },
  { no: 2, name: "SCIM_REQUEST_HTTP_STATUS_201" },
  { no: 3, name: "SCIM_REQUEST_HTTP_STATUS_204" },
  { no: 4, name: "SCIM_REQUEST_HTTP_STATUS_400" },
  { no: 5, name: "SCIM_REQUEST_HTTP_STATUS_401" },
  { no: 6, name: "SCIM_REQUEST_HTTP_STATUS_404" },
]);

/**
 * @generated from message ssoready.v1.AppUser
 */
export class AppUser extends Message<AppUser> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string display_name = 2;
   */
  displayName = "";

  /**
   * @generated from field: string email = 3;
   */
  email = "";

  constructor(data?: PartialMessage<AppUser>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AppUser";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AppUser {
    return new AppUser().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AppUser {
    return new AppUser().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AppUser {
    return new AppUser().fromJsonString(jsonString, options);
  }

  static equals(a: AppUser | PlainMessage<AppUser> | undefined, b: AppUser | PlainMessage<AppUser> | undefined): boolean {
    return proto3.util.equals(AppUser, a, b);
  }
}

/**
 * @generated from message ssoready.v1.Environment
 */
export class Environment extends Message<Environment> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string redirect_url = 2;
   */
  redirectUrl = "";

  /**
   * @generated from field: string display_name = 3;
   */
  displayName = "";

  /**
   * @generated from field: string auth_url = 4;
   */
  authUrl = "";

  /**
   * @generated from field: string oauth_redirect_uri = 5;
   */
  oauthRedirectUri = "";

  constructor(data?: PartialMessage<Environment>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.Environment";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "redirect_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "auth_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "oauth_redirect_uri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Environment {
    return new Environment().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Environment {
    return new Environment().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Environment {
    return new Environment().fromJsonString(jsonString, options);
  }

  static equals(a: Environment | PlainMessage<Environment> | undefined, b: Environment | PlainMessage<Environment> | undefined): boolean {
    return proto3.util.equals(Environment, a, b);
  }
}

/**
 * @generated from message ssoready.v1.APIKey
 */
export class APIKey extends Message<APIKey> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string environment_id = 2;
   */
  environmentId = "";

  /**
   * @generated from field: string secret_token = 3;
   */
  secretToken = "";

  /**
   * @generated from field: bool has_management_api_access = 4;
   */
  hasManagementApiAccess = false;

  constructor(data?: PartialMessage<APIKey>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.APIKey";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "environment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "secret_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "has_management_api_access", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): APIKey {
    return new APIKey().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): APIKey {
    return new APIKey().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): APIKey {
    return new APIKey().fromJsonString(jsonString, options);
  }

  static equals(a: APIKey | PlainMessage<APIKey> | undefined, b: APIKey | PlainMessage<APIKey> | undefined): boolean {
    return proto3.util.equals(APIKey, a, b);
  }
}

/**
 * @generated from message ssoready.v1.SAMLOAuthClient
 */
export class SAMLOAuthClient extends Message<SAMLOAuthClient> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string environment_id = 2;
   */
  environmentId = "";

  /**
   * @generated from field: string client_secret = 3;
   */
  clientSecret = "";

  constructor(data?: PartialMessage<SAMLOAuthClient>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.SAMLOAuthClient";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "environment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "client_secret", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SAMLOAuthClient {
    return new SAMLOAuthClient().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SAMLOAuthClient {
    return new SAMLOAuthClient().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SAMLOAuthClient {
    return new SAMLOAuthClient().fromJsonString(jsonString, options);
  }

  static equals(a: SAMLOAuthClient | PlainMessage<SAMLOAuthClient> | undefined, b: SAMLOAuthClient | PlainMessage<SAMLOAuthClient> | undefined): boolean {
    return proto3.util.equals(SAMLOAuthClient, a, b);
  }
}

/**
 * @generated from message ssoready.v1.Organization
 */
export class Organization extends Message<Organization> {
  /**
   * Unique identifier for this organization.
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * The environment this organization belongs to.
   *
   * @generated from field: string environment_id = 2;
   */
  environmentId = "";

  /**
   * An identifier you can attach to an organization. Meant to be used to correlate an SSOReady organization to your
   * internal equivalent concept.
   *
   * External IDs are unique within an environment. No two organizations in the same environment can have
   * the same external ID.
   *
   * @generated from field: string external_id = 3;
   */
  externalId = "";

  /**
   * A list of domains that users from this organization use.
   *
   * SAML connections and SCIM directories within this organization will only produce users whose email are included in
   * `domains`. SSOReady will reject SAML and SCIM users that do not fall within `domains`.
   *
   * @generated from field: repeated string domains = 4;
   */
  domains: string[] = [];

  constructor(data?: PartialMessage<Organization>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.Organization";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "environment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "external_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "domains", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Organization {
    return new Organization().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Organization {
    return new Organization().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Organization {
    return new Organization().fromJsonString(jsonString, options);
  }

  static equals(a: Organization | PlainMessage<Organization> | undefined, b: Organization | PlainMessage<Organization> | undefined): boolean {
    return proto3.util.equals(Organization, a, b);
  }
}

/**
 * @generated from message ssoready.v1.SAMLConnection
 */
export class SAMLConnection extends Message<SAMLConnection> {
  /**
   * Unique identifier for this SAML connection.
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * The organization this SAML connection belongs to.
   *
   * @generated from field: string organization_id = 2;
   */
  organizationId = "";

  /**
   * Whether this is the primary SAML connection for the organization.
   *
   * @generated from field: bool primary = 8;
   */
  primary = false;

  /**
   * URL to redirect to when initiating SAML flows.
   *
   * IDP redirect URLs are assigned by an Identity Provider, and need to be inputted into SSOReady.
   *
   * @generated from field: string idp_redirect_url = 3;
   */
  idpRedirectUrl = "";

  /**
   * Certificate to authenticate SAML assertions. This is a PEM-encoded X.509 certificate.
   *
   * IDP certificates are assigned by an Identity Provider, and need to be inputted into SSOReady.
   *
   * @generated from field: string idp_certificate = 4;
   */
  idpCertificate = "";

  /**
   * Identifier for the identity provider when handling SAML operations.
   *
   * IDP entity IDs are assigned by an Identity Provider, and need to be inputted into SSOReady.
   *
   * @generated from field: string idp_entity_id = 5;
   */
  idpEntityId = "";

  /**
   * Identifier for the SAML connection when handling SAML operations.
   *
   * SP entity IDs are assigned by SSOReady, and need to be inputted into your customer's Identity Provider.
   *
   * @generated from field: string sp_entity_id = 6;
   */
  spEntityId = "";

  /**
   * URL the Identity Provider redirects to when transmitting SAML assertions. Stands for "Service Provider Assertion
   * Consumer Service" URL.
   *
   * SP ACS URLs are assigned by SSOReady, and need to be inputted into your customer's Identity Provider.
   *
   * @generated from field: string sp_acs_url = 7;
   */
  spAcsUrl = "";

  constructor(data?: PartialMessage<SAMLConnection>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.SAMLConnection";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "organization_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "primary", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "idp_redirect_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "idp_certificate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "idp_entity_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "sp_entity_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "sp_acs_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SAMLConnection {
    return new SAMLConnection().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SAMLConnection {
    return new SAMLConnection().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SAMLConnection {
    return new SAMLConnection().fromJsonString(jsonString, options);
  }

  static equals(a: SAMLConnection | PlainMessage<SAMLConnection> | undefined, b: SAMLConnection | PlainMessage<SAMLConnection> | undefined): boolean {
    return proto3.util.equals(SAMLConnection, a, b);
  }
}

/**
 * @generated from message ssoready.v1.SAMLFlow
 */
export class SAMLFlow extends Message<SAMLFlow> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string saml_connection_id = 2;
   */
  samlConnectionId = "";

  /**
   * @generated from field: ssoready.v1.SAMLFlowStatus status = 17;
   */
  status = SAMLFlowStatus.SAML_FLOW_STATUS_UNSPECIFIED;

  /**
   * @generated from oneof ssoready.v1.SAMLFlow.error
   */
  error: {
    /**
     * @generated from field: google.protobuf.Empty saml_connection_not_configured = 26;
     */
    value: Empty;
    case: "samlConnectionNotConfigured";
  } | {
    /**
     * @generated from field: google.protobuf.Empty environment_oauth_redirect_uri_not_configured = 27;
     */
    value: Empty;
    case: "environmentOauthRedirectUriNotConfigured";
  } | {
    /**
     * @generated from field: google.protobuf.Empty unsigned_assertion = 22;
     */
    value: Empty;
    case: "unsignedAssertion";
  } | {
    /**
     * @generated from field: string bad_issuer = 18;
     */
    value: string;
    case: "badIssuer";
  } | {
    /**
     * @generated from field: string bad_audience = 19;
     */
    value: string;
    case: "badAudience";
  } | {
    /**
     * @generated from field: string bad_signature_algorithm = 23;
     */
    value: string;
    case: "badSignatureAlgorithm";
  } | {
    /**
     * @generated from field: string bad_digest_algorithm = 24;
     */
    value: string;
    case: "badDigestAlgorithm";
  } | {
    /**
     * @generated from field: string bad_certificate = 25;
     */
    value: string;
    case: "badCertificate";
  } | {
    /**
     * @generated from field: string bad_subject_id = 20;
     */
    value: string;
    case: "badSubjectId";
  } | {
    /**
     * @generated from field: string email_outside_organization_domains = 21;
     */
    value: string;
    case: "emailOutsideOrganizationDomains";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: string state = 3;
   */
  state = "";

  /**
   * @generated from field: string email = 4;
   */
  email = "";

  /**
   * @generated from field: map<string, string> attributes = 5;
   */
  attributes: { [key: string]: string } = {};

  /**
   * @generated from field: google.protobuf.Timestamp create_time = 6;
   */
  createTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp update_time = 7;
   */
  updateTime?: Timestamp;

  /**
   * @generated from field: string auth_redirect_url = 8;
   */
  authRedirectUrl = "";

  /**
   * @generated from field: google.protobuf.Timestamp get_redirect_time = 9;
   */
  getRedirectTime?: Timestamp;

  /**
   * @generated from field: string initiate_request = 10;
   */
  initiateRequest = "";

  /**
   * @generated from field: google.protobuf.Timestamp initiate_time = 11;
   */
  initiateTime?: Timestamp;

  /**
   * @generated from field: string assertion = 12;
   */
  assertion = "";

  /**
   * @generated from field: string app_redirect_url = 13;
   */
  appRedirectUrl = "";

  /**
   * @generated from field: google.protobuf.Timestamp receive_assertion_time = 14;
   */
  receiveAssertionTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp redeem_time = 15;
   */
  redeemTime?: Timestamp;

  /**
   * @generated from field: string redeem_response = 16;
   */
  redeemResponse = "";

  constructor(data?: PartialMessage<SAMLFlow>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.SAMLFlow";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "saml_connection_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "status", kind: "enum", T: proto3.getEnumType(SAMLFlowStatus) },
    { no: 26, name: "saml_connection_not_configured", kind: "message", T: Empty, oneof: "error" },
    { no: 27, name: "environment_oauth_redirect_uri_not_configured", kind: "message", T: Empty, oneof: "error" },
    { no: 22, name: "unsigned_assertion", kind: "message", T: Empty, oneof: "error" },
    { no: 18, name: "bad_issuer", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "error" },
    { no: 19, name: "bad_audience", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "error" },
    { no: 23, name: "bad_signature_algorithm", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "error" },
    { no: 24, name: "bad_digest_algorithm", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "error" },
    { no: 25, name: "bad_certificate", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "error" },
    { no: 20, name: "bad_subject_id", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "error" },
    { no: 21, name: "email_outside_organization_domains", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "error" },
    { no: 3, name: "state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "attributes", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 6, name: "create_time", kind: "message", T: Timestamp },
    { no: 7, name: "update_time", kind: "message", T: Timestamp },
    { no: 8, name: "auth_redirect_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "get_redirect_time", kind: "message", T: Timestamp },
    { no: 10, name: "initiate_request", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "initiate_time", kind: "message", T: Timestamp },
    { no: 12, name: "assertion", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "app_redirect_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "receive_assertion_time", kind: "message", T: Timestamp },
    { no: 15, name: "redeem_time", kind: "message", T: Timestamp },
    { no: 16, name: "redeem_response", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SAMLFlow {
    return new SAMLFlow().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SAMLFlow {
    return new SAMLFlow().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SAMLFlow {
    return new SAMLFlow().fromJsonString(jsonString, options);
  }

  static equals(a: SAMLFlow | PlainMessage<SAMLFlow> | undefined, b: SAMLFlow | PlainMessage<SAMLFlow> | undefined): boolean {
    return proto3.util.equals(SAMLFlow, a, b);
  }
}

/**
 * @generated from message ssoready.v1.SCIMDirectory
 */
export class SCIMDirectory extends Message<SCIMDirectory> {
  /**
   * Unique identifier for this SCIM directory.
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * The organization this SCIM directory belongs to.
   *
   * @generated from field: string organization_id = 2;
   */
  organizationId = "";

  /**
   * Whether this is the primary SCIM directory for the organization.
   *
   * @generated from field: bool primary = 3;
   */
  primary = false;

  /**
   * Base URL the Identity Provider uses to perform SCIM HTTP requests.
   *
   * SCIM base URLs are assigned by SSOReady, and need to be inputted into your customer's Identity Provider.
   *
   * @generated from field: string scim_base_url = 4;
   */
  scimBaseUrl = "";

  /**
   * Whether this SCIM directory has a bearer token assigned.
   *
   * SSOReady only stores a hash of the bearer token. To get a bearer token value, you must rotate this SCIM directory's
   * bearer token.
   *
   * @generated from field: bool has_client_bearer_token = 6;
   */
  hasClientBearerToken = false;

  constructor(data?: PartialMessage<SCIMDirectory>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.SCIMDirectory";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "organization_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "primary", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "scim_base_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "has_client_bearer_token", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SCIMDirectory {
    return new SCIMDirectory().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SCIMDirectory {
    return new SCIMDirectory().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SCIMDirectory {
    return new SCIMDirectory().fromJsonString(jsonString, options);
  }

  static equals(a: SCIMDirectory | PlainMessage<SCIMDirectory> | undefined, b: SCIMDirectory | PlainMessage<SCIMDirectory> | undefined): boolean {
    return proto3.util.equals(SCIMDirectory, a, b);
  }
}

/**
 * @generated from message ssoready.v1.SCIMUser
 */
export class SCIMUser extends Message<SCIMUser> {
  /**
   * Unique identifier for this SCIM user.
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * SCIM directory this SCIM user belongs to.
   *
   * @generated from field: string scim_directory_id = 2;
   */
  scimDirectoryId = "";

  /**
   * The SCIM user's email address.
   *
   * @generated from field: string email = 3;
   */
  email = "";

  /**
   * Whether the SCIM user has been deleted or deprovisioned from its SCIM directory.
   *
   * @generated from field: bool deleted = 4;
   */
  deleted = false;

  /**
   * Arbitrary, potentially nested, attributes the Identity Provider included about the user.
   *
   * Typically, these `attributes` are used to pass along the user's first/last name, or whether they should be
   * considered an admin within their company.
   *
   * @generated from field: google.protobuf.Struct attributes = 5;
   */
  attributes?: Struct;

  constructor(data?: PartialMessage<SCIMUser>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.SCIMUser";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "scim_directory_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "deleted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "attributes", kind: "message", T: Struct },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SCIMUser {
    return new SCIMUser().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SCIMUser {
    return new SCIMUser().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SCIMUser {
    return new SCIMUser().fromJsonString(jsonString, options);
  }

  static equals(a: SCIMUser | PlainMessage<SCIMUser> | undefined, b: SCIMUser | PlainMessage<SCIMUser> | undefined): boolean {
    return proto3.util.equals(SCIMUser, a, b);
  }
}

/**
 * @generated from message ssoready.v1.SCIMGroup
 */
export class SCIMGroup extends Message<SCIMGroup> {
  /**
   * Unique identifier for this SCIM group.
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * SCIM directory this SCIM group belongs to.
   *
   * @generated from field: string scim_directory_id = 2;
   */
  scimDirectoryId = "";

  /**
   * A human-friendly name for the SCIM group.
   *
   * @generated from field: string display_name = 3;
   */
  displayName = "";

  /**
   * Whether the SCIM group has been deleted or deprovisioned from its SCIM directory.
   *
   * Identity Providers are inconsistent about reliably deleting SCIM groups. Many Identity Providers will deprovision
   * the users inside a group, but not the group itself. For this reason, it's typical to ignore this field until a
   * specific need arises.
   *
   * @generated from field: bool deleted = 6;
   */
  deleted = false;

  /**
   * Arbitrary, potentially nested, attributes the Identity Provider included about the group.
   *
   * Identity Providers are inconsistent about supporting sending custom attributes on groups. For this reason, it's
   * typical to not rely on them until a specific need arises.
   *
   * @generated from field: google.protobuf.Struct attributes = 4;
   */
  attributes?: Struct;

  constructor(data?: PartialMessage<SCIMGroup>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.SCIMGroup";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "scim_directory_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "deleted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "attributes", kind: "message", T: Struct },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SCIMGroup {
    return new SCIMGroup().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SCIMGroup {
    return new SCIMGroup().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SCIMGroup {
    return new SCIMGroup().fromJsonString(jsonString, options);
  }

  static equals(a: SCIMGroup | PlainMessage<SCIMGroup> | undefined, b: SCIMGroup | PlainMessage<SCIMGroup> | undefined): boolean {
    return proto3.util.equals(SCIMGroup, a, b);
  }
}

/**
 * @generated from message ssoready.v1.SCIMRequest
 */
export class SCIMRequest extends Message<SCIMRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string scim_directory_id = 2;
   */
  scimDirectoryId = "";

  /**
   * @generated from field: google.protobuf.Timestamp timestamp = 3;
   */
  timestamp?: Timestamp;

  /**
   * @generated from field: string http_request_url = 4;
   */
  httpRequestUrl = "";

  /**
   * @generated from field: ssoready.v1.SCIMRequestHTTPMethod http_request_method = 5;
   */
  httpRequestMethod = SCIMRequestHTTPMethod.SCIM_REQUEST_HTTP_METHOD_UNSPECIFIED;

  /**
   * @generated from field: google.protobuf.Struct http_request_body = 7;
   */
  httpRequestBody?: Struct;

  /**
   * @generated from field: ssoready.v1.SCIMRequestHTTPStatus http_response_status = 8;
   */
  httpResponseStatus = SCIMRequestHTTPStatus.SCIM_REQUEST_HTTP_STATUS_UNSPECIFIED;

  /**
   * @generated from field: google.protobuf.Struct http_response_body = 9;
   */
  httpResponseBody?: Struct;

  /**
   * @generated from oneof ssoready.v1.SCIMRequest.error
   */
  error: {
    /**
     * @generated from field: google.protobuf.Empty bad_bearer_token = 10;
     */
    value: Empty;
    case: "badBearerToken";
  } | {
    /**
     * @generated from field: string bad_username = 11;
     */
    value: string;
    case: "badUsername";
  } | {
    /**
     * @generated from field: string email_outside_organization_domains = 12;
     */
    value: string;
    case: "emailOutsideOrganizationDomains";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<SCIMRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.SCIMRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "scim_directory_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "timestamp", kind: "message", T: Timestamp },
    { no: 4, name: "http_request_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "http_request_method", kind: "enum", T: proto3.getEnumType(SCIMRequestHTTPMethod) },
    { no: 7, name: "http_request_body", kind: "message", T: Struct },
    { no: 8, name: "http_response_status", kind: "enum", T: proto3.getEnumType(SCIMRequestHTTPStatus) },
    { no: 9, name: "http_response_body", kind: "message", T: Struct },
    { no: 10, name: "bad_bearer_token", kind: "message", T: Empty, oneof: "error" },
    { no: 11, name: "bad_username", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "error" },
    { no: 12, name: "email_outside_organization_domains", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "error" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SCIMRequest {
    return new SCIMRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SCIMRequest {
    return new SCIMRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SCIMRequest {
    return new SCIMRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SCIMRequest | PlainMessage<SCIMRequest> | undefined, b: SCIMRequest | PlainMessage<SCIMRequest> | undefined): boolean {
    return proto3.util.equals(SCIMRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.GetSAMLRedirectURLRequest
 */
export class GetSAMLRedirectURLRequest extends Message<GetSAMLRedirectURLRequest> {
  /**
   * The SAML connection to start a SAML login for.
   *
   * One of `samlConnectionId`, `organizationId`, or `organizationExternalId` must be specified.
   *
   * @generated from field: string saml_connection_id = 1;
   */
  samlConnectionId = "";

  /**
   * The ID of the organization to start a SAML login for.
   *
   * The primary SAML connection in this organization will be used for logins.
   *
   * One of `samlConnectionId`, `organizationId`, or `organizationExternalId` must be specified.
   *
   * @generated from field: string organization_id = 2;
   */
  organizationId = "";

  /**
   * The `externalId` of the organization to start a SAML login for.
   *
   * The primary SAML connection in this organization will be used for logins.
   *
   * One of `samlConnectionId`, `organizationId`, or `organizationExternalId` must be specified.
   *
   * @generated from field: string organization_external_id = 3;
   */
  organizationExternalId = "";

  /**
   * This string will be returned back to you when you redeem this login's SAML access code.
   *
   * You can do anything you like with this `state`, but the most common use-case is to keep track of where to redirect
   * your user back to after logging in with SAML.
   *
   * @generated from field: string state = 4;
   */
  state = "";

  constructor(data?: PartialMessage<GetSAMLRedirectURLRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.GetSAMLRedirectURLRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "saml_connection_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "organization_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "organization_external_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSAMLRedirectURLRequest {
    return new GetSAMLRedirectURLRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSAMLRedirectURLRequest {
    return new GetSAMLRedirectURLRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSAMLRedirectURLRequest {
    return new GetSAMLRedirectURLRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetSAMLRedirectURLRequest | PlainMessage<GetSAMLRedirectURLRequest> | undefined, b: GetSAMLRedirectURLRequest | PlainMessage<GetSAMLRedirectURLRequest> | undefined): boolean {
    return proto3.util.equals(GetSAMLRedirectURLRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.GetSAMLRedirectURLResponse
 */
export class GetSAMLRedirectURLResponse extends Message<GetSAMLRedirectURLResponse> {
  /**
   * Redirect your user to this URL to start a SAML login.
   *
   * @generated from field: string redirect_url = 1;
   */
  redirectUrl = "";

  constructor(data?: PartialMessage<GetSAMLRedirectURLResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.GetSAMLRedirectURLResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "redirect_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSAMLRedirectURLResponse {
    return new GetSAMLRedirectURLResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSAMLRedirectURLResponse {
    return new GetSAMLRedirectURLResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSAMLRedirectURLResponse {
    return new GetSAMLRedirectURLResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetSAMLRedirectURLResponse | PlainMessage<GetSAMLRedirectURLResponse> | undefined, b: GetSAMLRedirectURLResponse | PlainMessage<GetSAMLRedirectURLResponse> | undefined): boolean {
    return proto3.util.equals(GetSAMLRedirectURLResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.RedeemSAMLAccessCodeRequest
 */
export class RedeemSAMLAccessCodeRequest extends Message<RedeemSAMLAccessCodeRequest> {
  /**
   * The SAML access code to redeem.
   *
   * @generated from field: string saml_access_code = 1;
   */
  samlAccessCode = "";

  constructor(data?: PartialMessage<RedeemSAMLAccessCodeRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.RedeemSAMLAccessCodeRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "saml_access_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RedeemSAMLAccessCodeRequest {
    return new RedeemSAMLAccessCodeRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RedeemSAMLAccessCodeRequest {
    return new RedeemSAMLAccessCodeRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RedeemSAMLAccessCodeRequest {
    return new RedeemSAMLAccessCodeRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RedeemSAMLAccessCodeRequest | PlainMessage<RedeemSAMLAccessCodeRequest> | undefined, b: RedeemSAMLAccessCodeRequest | PlainMessage<RedeemSAMLAccessCodeRequest> | undefined): boolean {
    return proto3.util.equals(RedeemSAMLAccessCodeRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.RedeemSAMLAccessCodeResponse
 */
export class RedeemSAMLAccessCodeResponse extends Message<RedeemSAMLAccessCodeResponse> {
  /**
   * The user's email address.
   *
   * @generated from field: string email = 1;
   */
  email = "";

  /**
   * The `state` you provided when getting a SAML initiation URL, if any.
   *
   * If your user logged in to your product using Identity Provider-initiated SAML (e.g. they clicked on your app inside
   * their corporate Okta dashboard), then `state` will be empty.
   *
   * SSOReady validates the authenticity of non-empty `state` values. You do not need to implement your own CSRF on top
   * of it, but doing so anyway will have no bad consequences.
   *
   * @generated from field: string state = 2;
   */
  state = "";

  /**
   * Arbitrary key-value pairs the Identity Provider included about the user.
   *
   * Typically, these `attributes` are used to pass along the user's first/last name, or whether they should be
   * considered an admin within their company.
   *
   * @generated from field: map<string, string> attributes = 3;
   */
  attributes: { [key: string]: string } = {};

  /**
   * The ID of the organization this user belongs to.
   *
   * @generated from field: string organization_id = 4;
   */
  organizationId = "";

  /**
   * The `externalId`, if any, of the organization this user belongs to.
   *
   * @generated from field: string organization_external_id = 5;
   */
  organizationExternalId = "";

  /**
   * A unique identifier of this particular SAML login. It is not a secret. You can safely log it.
   *
   * SSOReady maintains an audit log of every SAML login. Use this SAML flow ID to find this login in the audit logs.
   *
   * @generated from field: string saml_flow_id = 6;
   */
  samlFlowId = "";

  constructor(data?: PartialMessage<RedeemSAMLAccessCodeResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.RedeemSAMLAccessCodeResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "attributes", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 4, name: "organization_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "organization_external_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "saml_flow_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RedeemSAMLAccessCodeResponse {
    return new RedeemSAMLAccessCodeResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RedeemSAMLAccessCodeResponse {
    return new RedeemSAMLAccessCodeResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RedeemSAMLAccessCodeResponse {
    return new RedeemSAMLAccessCodeResponse().fromJsonString(jsonString, options);
  }

  static equals(a: RedeemSAMLAccessCodeResponse | PlainMessage<RedeemSAMLAccessCodeResponse> | undefined, b: RedeemSAMLAccessCodeResponse | PlainMessage<RedeemSAMLAccessCodeResponse> | undefined): boolean {
    return proto3.util.equals(RedeemSAMLAccessCodeResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.ListSCIMUsersRequest
 */
export class ListSCIMUsersRequest extends Message<ListSCIMUsersRequest> {
  /**
   * The SCIM directory to list from.
   *
   * One of `scimDirectoryId`, `organizationId`, or `organizationExternalId` must be specified.
   *
   * @generated from field: string scim_directory_id = 1;
   */
  scimDirectoryId = "";

  /**
   * The ID of the organization to list from. The primary SCIM directory of this organization is used.
   *
   * One of `scimDirectoryId`, `organizationId`, or `organizationExternalId` must be specified.
   *
   * @generated from field: string organization_id = 2;
   */
  organizationId = "";

  /**
   * The `externalId` of the organization to list from. The primary SCIM directory of this organization is used.
   *
   * One of `scimDirectoryId`, `organizationId`, or `organizationExternalId` must be specified.
   *
   * @generated from field: string organization_external_id = 3;
   */
  organizationExternalId = "";

  /**
   * If specified, only users that are members of this SCIM group are returned.
   *
   * @generated from field: string scim_group_id = 4;
   */
  scimGroupId = "";

  /**
   * Pagination token. Leave empty to get the first page of results.
   *
   * @generated from field: string page_token = 5;
   */
  pageToken = "";

  constructor(data?: PartialMessage<ListSCIMUsersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.ListSCIMUsersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "scim_directory_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "organization_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "organization_external_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "scim_group_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListSCIMUsersRequest {
    return new ListSCIMUsersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListSCIMUsersRequest {
    return new ListSCIMUsersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListSCIMUsersRequest {
    return new ListSCIMUsersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListSCIMUsersRequest | PlainMessage<ListSCIMUsersRequest> | undefined, b: ListSCIMUsersRequest | PlainMessage<ListSCIMUsersRequest> | undefined): boolean {
    return proto3.util.equals(ListSCIMUsersRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.ListSCIMUsersResponse
 */
export class ListSCIMUsersResponse extends Message<ListSCIMUsersResponse> {
  /**
   * List of SCIM users.
   *
   * @generated from field: repeated ssoready.v1.SCIMUser scim_users = 1;
   */
  scimUsers: SCIMUser[] = [];

  /**
   * Value to use as `pageToken` for the next page of data. Empty if there is no more data.
   *
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  constructor(data?: PartialMessage<ListSCIMUsersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.ListSCIMUsersResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "scim_users", kind: "message", T: SCIMUser, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListSCIMUsersResponse {
    return new ListSCIMUsersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListSCIMUsersResponse {
    return new ListSCIMUsersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListSCIMUsersResponse {
    return new ListSCIMUsersResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListSCIMUsersResponse | PlainMessage<ListSCIMUsersResponse> | undefined, b: ListSCIMUsersResponse | PlainMessage<ListSCIMUsersResponse> | undefined): boolean {
    return proto3.util.equals(ListSCIMUsersResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.GetSCIMUserRequest
 */
export class GetSCIMUserRequest extends Message<GetSCIMUserRequest> {
  /**
   * ID of the SCIM user to get.
   *
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<GetSCIMUserRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.GetSCIMUserRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSCIMUserRequest {
    return new GetSCIMUserRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSCIMUserRequest {
    return new GetSCIMUserRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSCIMUserRequest {
    return new GetSCIMUserRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetSCIMUserRequest | PlainMessage<GetSCIMUserRequest> | undefined, b: GetSCIMUserRequest | PlainMessage<GetSCIMUserRequest> | undefined): boolean {
    return proto3.util.equals(GetSCIMUserRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.GetSCIMUserResponse
 */
export class GetSCIMUserResponse extends Message<GetSCIMUserResponse> {
  /**
   * The requested SCIM user.
   *
   * @generated from field: ssoready.v1.SCIMUser scim_user = 1;
   */
  scimUser?: SCIMUser;

  constructor(data?: PartialMessage<GetSCIMUserResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.GetSCIMUserResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "scim_user", kind: "message", T: SCIMUser },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSCIMUserResponse {
    return new GetSCIMUserResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSCIMUserResponse {
    return new GetSCIMUserResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSCIMUserResponse {
    return new GetSCIMUserResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetSCIMUserResponse | PlainMessage<GetSCIMUserResponse> | undefined, b: GetSCIMUserResponse | PlainMessage<GetSCIMUserResponse> | undefined): boolean {
    return proto3.util.equals(GetSCIMUserResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.ListSCIMGroupsRequest
 */
export class ListSCIMGroupsRequest extends Message<ListSCIMGroupsRequest> {
  /**
   * The SCIM directory to list from.
   *
   * One of `scimDirectoryId`, `organizationId`, or `organizationExternalId` must be specified.
   *
   * @generated from field: string scim_directory_id = 1;
   */
  scimDirectoryId = "";

  /**
   * The ID of the organization to list from. The primary SCIM directory of this organization is used.
   *
   * One of `scimDirectoryId`, `organizationId`, or `organizationExternalId` must be specified.
   *
   * @generated from field: string organization_id = 2;
   */
  organizationId = "";

  /**
   * The `externalId` of the organization to list from. The primary SCIM directory of this organization is used.
   *
   * One of `scimDirectoryId`, `organizationId`, or `organizationExternalId` must be specified.
   *
   * @generated from field: string organization_external_id = 3;
   */
  organizationExternalId = "";

  /**
   * Pagination token. Leave empty to get the first page of results.
   *
   * @generated from field: string page_token = 4;
   */
  pageToken = "";

  constructor(data?: PartialMessage<ListSCIMGroupsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.ListSCIMGroupsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "scim_directory_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "organization_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "organization_external_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListSCIMGroupsRequest {
    return new ListSCIMGroupsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListSCIMGroupsRequest {
    return new ListSCIMGroupsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListSCIMGroupsRequest {
    return new ListSCIMGroupsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListSCIMGroupsRequest | PlainMessage<ListSCIMGroupsRequest> | undefined, b: ListSCIMGroupsRequest | PlainMessage<ListSCIMGroupsRequest> | undefined): boolean {
    return proto3.util.equals(ListSCIMGroupsRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.ListSCIMGroupsResponse
 */
export class ListSCIMGroupsResponse extends Message<ListSCIMGroupsResponse> {
  /**
   * List of SCIM groups.
   *
   * @generated from field: repeated ssoready.v1.SCIMGroup scim_groups = 1;
   */
  scimGroups: SCIMGroup[] = [];

  /**
   * Value to use as `pageToken` for the next page of data. Empty if there is no more data.
   *
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  constructor(data?: PartialMessage<ListSCIMGroupsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.ListSCIMGroupsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "scim_groups", kind: "message", T: SCIMGroup, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListSCIMGroupsResponse {
    return new ListSCIMGroupsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListSCIMGroupsResponse {
    return new ListSCIMGroupsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListSCIMGroupsResponse {
    return new ListSCIMGroupsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListSCIMGroupsResponse | PlainMessage<ListSCIMGroupsResponse> | undefined, b: ListSCIMGroupsResponse | PlainMessage<ListSCIMGroupsResponse> | undefined): boolean {
    return proto3.util.equals(ListSCIMGroupsResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.GetSCIMGroupRequest
 */
export class GetSCIMGroupRequest extends Message<GetSCIMGroupRequest> {
  /**
   * ID of the SCIM group to get.
   *
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<GetSCIMGroupRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.GetSCIMGroupRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSCIMGroupRequest {
    return new GetSCIMGroupRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSCIMGroupRequest {
    return new GetSCIMGroupRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSCIMGroupRequest {
    return new GetSCIMGroupRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetSCIMGroupRequest | PlainMessage<GetSCIMGroupRequest> | undefined, b: GetSCIMGroupRequest | PlainMessage<GetSCIMGroupRequest> | undefined): boolean {
    return proto3.util.equals(GetSCIMGroupRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.GetSCIMGroupResponse
 */
export class GetSCIMGroupResponse extends Message<GetSCIMGroupResponse> {
  /**
   * The requested SCIM group.
   *
   * @generated from field: ssoready.v1.SCIMGroup scim_group = 1;
   */
  scimGroup?: SCIMGroup;

  constructor(data?: PartialMessage<GetSCIMGroupResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.GetSCIMGroupResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "scim_group", kind: "message", T: SCIMGroup },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSCIMGroupResponse {
    return new GetSCIMGroupResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSCIMGroupResponse {
    return new GetSCIMGroupResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSCIMGroupResponse {
    return new GetSCIMGroupResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetSCIMGroupResponse | PlainMessage<GetSCIMGroupResponse> | undefined, b: GetSCIMGroupResponse | PlainMessage<GetSCIMGroupResponse> | undefined): boolean {
    return proto3.util.equals(GetSCIMGroupResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.ListOrganizationsRequest
 */
export class ListOrganizationsRequest extends Message<ListOrganizationsRequest> {
  /**
   * Pagination token. Leave empty to get the first page of results.
   *
   * @generated from field: string page_token = 1;
   */
  pageToken = "";

  constructor(data?: PartialMessage<ListOrganizationsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.ListOrganizationsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListOrganizationsRequest {
    return new ListOrganizationsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListOrganizationsRequest {
    return new ListOrganizationsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListOrganizationsRequest {
    return new ListOrganizationsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListOrganizationsRequest | PlainMessage<ListOrganizationsRequest> | undefined, b: ListOrganizationsRequest | PlainMessage<ListOrganizationsRequest> | undefined): boolean {
    return proto3.util.equals(ListOrganizationsRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.ListOrganizationsResponse
 */
export class ListOrganizationsResponse extends Message<ListOrganizationsResponse> {
  /**
   * List of organizations.
   *
   * @generated from field: repeated ssoready.v1.Organization organizations = 1;
   */
  organizations: Organization[] = [];

  /**
   * Value to use as `pageToken` for the next page of data. Empty if there is no more data.
   *
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  constructor(data?: PartialMessage<ListOrganizationsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.ListOrganizationsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "organizations", kind: "message", T: Organization, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListOrganizationsResponse {
    return new ListOrganizationsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListOrganizationsResponse {
    return new ListOrganizationsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListOrganizationsResponse {
    return new ListOrganizationsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListOrganizationsResponse | PlainMessage<ListOrganizationsResponse> | undefined, b: ListOrganizationsResponse | PlainMessage<ListOrganizationsResponse> | undefined): boolean {
    return proto3.util.equals(ListOrganizationsResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.GetOrganizationRequest
 */
export class GetOrganizationRequest extends Message<GetOrganizationRequest> {
  /**
   * ID of the organization to get.
   *
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<GetOrganizationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.GetOrganizationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOrganizationRequest {
    return new GetOrganizationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOrganizationRequest {
    return new GetOrganizationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetOrganizationRequest {
    return new GetOrganizationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetOrganizationRequest | PlainMessage<GetOrganizationRequest> | undefined, b: GetOrganizationRequest | PlainMessage<GetOrganizationRequest> | undefined): boolean {
    return proto3.util.equals(GetOrganizationRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.GetOrganizationResponse
 */
export class GetOrganizationResponse extends Message<GetOrganizationResponse> {
  /**
   * The requested organization.
   *
   * @generated from field: ssoready.v1.Organization organization = 1;
   */
  organization?: Organization;

  constructor(data?: PartialMessage<GetOrganizationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.GetOrganizationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "organization", kind: "message", T: Organization },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOrganizationResponse {
    return new GetOrganizationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOrganizationResponse {
    return new GetOrganizationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetOrganizationResponse {
    return new GetOrganizationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetOrganizationResponse | PlainMessage<GetOrganizationResponse> | undefined, b: GetOrganizationResponse | PlainMessage<GetOrganizationResponse> | undefined): boolean {
    return proto3.util.equals(GetOrganizationResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.CreateOrganizationRequest
 */
export class CreateOrganizationRequest extends Message<CreateOrganizationRequest> {
  /**
   * @generated from field: ssoready.v1.Organization organization = 1;
   */
  organization?: Organization;

  constructor(data?: PartialMessage<CreateOrganizationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.CreateOrganizationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "organization", kind: "message", T: Organization },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateOrganizationRequest {
    return new CreateOrganizationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateOrganizationRequest {
    return new CreateOrganizationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateOrganizationRequest {
    return new CreateOrganizationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateOrganizationRequest | PlainMessage<CreateOrganizationRequest> | undefined, b: CreateOrganizationRequest | PlainMessage<CreateOrganizationRequest> | undefined): boolean {
    return proto3.util.equals(CreateOrganizationRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.CreateOrganizationResponse
 */
export class CreateOrganizationResponse extends Message<CreateOrganizationResponse> {
  /**
   * The created organization.
   *
   * @generated from field: ssoready.v1.Organization organization = 1;
   */
  organization?: Organization;

  constructor(data?: PartialMessage<CreateOrganizationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.CreateOrganizationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "organization", kind: "message", T: Organization },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateOrganizationResponse {
    return new CreateOrganizationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateOrganizationResponse {
    return new CreateOrganizationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateOrganizationResponse {
    return new CreateOrganizationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateOrganizationResponse | PlainMessage<CreateOrganizationResponse> | undefined, b: CreateOrganizationResponse | PlainMessage<CreateOrganizationResponse> | undefined): boolean {
    return proto3.util.equals(CreateOrganizationResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.UpdateOrganizationRequest
 */
export class UpdateOrganizationRequest extends Message<UpdateOrganizationRequest> {
  /**
   * ID of the organization to update.
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * The updated organization.
   *
   * @generated from field: ssoready.v1.Organization organization = 2;
   */
  organization?: Organization;

  constructor(data?: PartialMessage<UpdateOrganizationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.UpdateOrganizationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "organization", kind: "message", T: Organization },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateOrganizationRequest {
    return new UpdateOrganizationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateOrganizationRequest {
    return new UpdateOrganizationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateOrganizationRequest {
    return new UpdateOrganizationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateOrganizationRequest | PlainMessage<UpdateOrganizationRequest> | undefined, b: UpdateOrganizationRequest | PlainMessage<UpdateOrganizationRequest> | undefined): boolean {
    return proto3.util.equals(UpdateOrganizationRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.UpdateOrganizationResponse
 */
export class UpdateOrganizationResponse extends Message<UpdateOrganizationResponse> {
  /**
   * The updated organization.
   *
   * @generated from field: ssoready.v1.Organization organization = 1;
   */
  organization?: Organization;

  constructor(data?: PartialMessage<UpdateOrganizationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.UpdateOrganizationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "organization", kind: "message", T: Organization },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateOrganizationResponse {
    return new UpdateOrganizationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateOrganizationResponse {
    return new UpdateOrganizationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateOrganizationResponse {
    return new UpdateOrganizationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateOrganizationResponse | PlainMessage<UpdateOrganizationResponse> | undefined, b: UpdateOrganizationResponse | PlainMessage<UpdateOrganizationResponse> | undefined): boolean {
    return proto3.util.equals(UpdateOrganizationResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.CreateSetupURLRequest
 */
export class CreateSetupURLRequest extends Message<CreateSetupURLRequest> {
  /**
   * The organization that the setup URL is for.
   *
   * @generated from field: string organization_id = 1;
   */
  organizationId = "";

  /**
   * Whether the setup URL lets the user manage SAML connections.
   *
   * @generated from field: bool can_manage_saml = 2;
   */
  canManageSaml = false;

  /**
   * Whether the setup URL lets the user manage SCIM directories.
   *
   * @generated from field: bool can_manage_scim = 3;
   */
  canManageScim = false;

  constructor(data?: PartialMessage<CreateSetupURLRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.CreateSetupURLRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "organization_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "can_manage_saml", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "can_manage_scim", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateSetupURLRequest {
    return new CreateSetupURLRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateSetupURLRequest {
    return new CreateSetupURLRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateSetupURLRequest {
    return new CreateSetupURLRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateSetupURLRequest | PlainMessage<CreateSetupURLRequest> | undefined, b: CreateSetupURLRequest | PlainMessage<CreateSetupURLRequest> | undefined): boolean {
    return proto3.util.equals(CreateSetupURLRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.CreateSetupURLResponse
 */
export class CreateSetupURLResponse extends Message<CreateSetupURLResponse> {
  /**
   * The one-time, short-lived self-serve setup URL.
   *
   * Do not log or store this URL. Because this URL is one-time, loading it yourself means your customer will not be
   * able to load it after you.
   *
   * @generated from field: string url = 1;
   */
  url = "";

  constructor(data?: PartialMessage<CreateSetupURLResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.CreateSetupURLResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateSetupURLResponse {
    return new CreateSetupURLResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateSetupURLResponse {
    return new CreateSetupURLResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateSetupURLResponse {
    return new CreateSetupURLResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateSetupURLResponse | PlainMessage<CreateSetupURLResponse> | undefined, b: CreateSetupURLResponse | PlainMessage<CreateSetupURLResponse> | undefined): boolean {
    return proto3.util.equals(CreateSetupURLResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.ListSAMLConnectionsRequest
 */
export class ListSAMLConnectionsRequest extends Message<ListSAMLConnectionsRequest> {
  /**
   * The organization the SAML connections belong to.
   *
   * @generated from field: string organization_id = 1;
   */
  organizationId = "";

  /**
   * Pagination token. Leave empty to get the first page of results.
   *
   * @generated from field: string page_token = 2;
   */
  pageToken = "";

  constructor(data?: PartialMessage<ListSAMLConnectionsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.ListSAMLConnectionsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "organization_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListSAMLConnectionsRequest {
    return new ListSAMLConnectionsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListSAMLConnectionsRequest {
    return new ListSAMLConnectionsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListSAMLConnectionsRequest {
    return new ListSAMLConnectionsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListSAMLConnectionsRequest | PlainMessage<ListSAMLConnectionsRequest> | undefined, b: ListSAMLConnectionsRequest | PlainMessage<ListSAMLConnectionsRequest> | undefined): boolean {
    return proto3.util.equals(ListSAMLConnectionsRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.ListSAMLConnectionsResponse
 */
export class ListSAMLConnectionsResponse extends Message<ListSAMLConnectionsResponse> {
  /**
   * The list of SAML connections.
   *
   * @generated from field: repeated ssoready.v1.SAMLConnection saml_connections = 1;
   */
  samlConnections: SAMLConnection[] = [];

  /**
   * Value to use as `pageToken` for the next page of data. Empty if there is no more data.
   *
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  constructor(data?: PartialMessage<ListSAMLConnectionsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.ListSAMLConnectionsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "saml_connections", kind: "message", T: SAMLConnection, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListSAMLConnectionsResponse {
    return new ListSAMLConnectionsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListSAMLConnectionsResponse {
    return new ListSAMLConnectionsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListSAMLConnectionsResponse {
    return new ListSAMLConnectionsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListSAMLConnectionsResponse | PlainMessage<ListSAMLConnectionsResponse> | undefined, b: ListSAMLConnectionsResponse | PlainMessage<ListSAMLConnectionsResponse> | undefined): boolean {
    return proto3.util.equals(ListSAMLConnectionsResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.GetSAMLConnectionRequest
 */
export class GetSAMLConnectionRequest extends Message<GetSAMLConnectionRequest> {
  /**
   * ID of the SAML connection to get.
   *
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<GetSAMLConnectionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.GetSAMLConnectionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSAMLConnectionRequest {
    return new GetSAMLConnectionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSAMLConnectionRequest {
    return new GetSAMLConnectionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSAMLConnectionRequest {
    return new GetSAMLConnectionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetSAMLConnectionRequest | PlainMessage<GetSAMLConnectionRequest> | undefined, b: GetSAMLConnectionRequest | PlainMessage<GetSAMLConnectionRequest> | undefined): boolean {
    return proto3.util.equals(GetSAMLConnectionRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.GetSAMLConnectionResponse
 */
export class GetSAMLConnectionResponse extends Message<GetSAMLConnectionResponse> {
  /**
   * The requested SAML connection.
   *
   * @generated from field: ssoready.v1.SAMLConnection saml_connection = 1;
   */
  samlConnection?: SAMLConnection;

  constructor(data?: PartialMessage<GetSAMLConnectionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.GetSAMLConnectionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "saml_connection", kind: "message", T: SAMLConnection },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSAMLConnectionResponse {
    return new GetSAMLConnectionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSAMLConnectionResponse {
    return new GetSAMLConnectionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSAMLConnectionResponse {
    return new GetSAMLConnectionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetSAMLConnectionResponse | PlainMessage<GetSAMLConnectionResponse> | undefined, b: GetSAMLConnectionResponse | PlainMessage<GetSAMLConnectionResponse> | undefined): boolean {
    return proto3.util.equals(GetSAMLConnectionResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.CreateSAMLConnectionRequest
 */
export class CreateSAMLConnectionRequest extends Message<CreateSAMLConnectionRequest> {
  /**
   * The SAML connection to create.
   *
   * @generated from field: ssoready.v1.SAMLConnection saml_connection = 1;
   */
  samlConnection?: SAMLConnection;

  constructor(data?: PartialMessage<CreateSAMLConnectionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.CreateSAMLConnectionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "saml_connection", kind: "message", T: SAMLConnection },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateSAMLConnectionRequest {
    return new CreateSAMLConnectionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateSAMLConnectionRequest {
    return new CreateSAMLConnectionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateSAMLConnectionRequest {
    return new CreateSAMLConnectionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateSAMLConnectionRequest | PlainMessage<CreateSAMLConnectionRequest> | undefined, b: CreateSAMLConnectionRequest | PlainMessage<CreateSAMLConnectionRequest> | undefined): boolean {
    return proto3.util.equals(CreateSAMLConnectionRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.CreateSAMLConnectionResponse
 */
export class CreateSAMLConnectionResponse extends Message<CreateSAMLConnectionResponse> {
  /**
   * The created SAML connection.
   *
   * @generated from field: ssoready.v1.SAMLConnection saml_connection = 1;
   */
  samlConnection?: SAMLConnection;

  constructor(data?: PartialMessage<CreateSAMLConnectionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.CreateSAMLConnectionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "saml_connection", kind: "message", T: SAMLConnection },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateSAMLConnectionResponse {
    return new CreateSAMLConnectionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateSAMLConnectionResponse {
    return new CreateSAMLConnectionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateSAMLConnectionResponse {
    return new CreateSAMLConnectionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateSAMLConnectionResponse | PlainMessage<CreateSAMLConnectionResponse> | undefined, b: CreateSAMLConnectionResponse | PlainMessage<CreateSAMLConnectionResponse> | undefined): boolean {
    return proto3.util.equals(CreateSAMLConnectionResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.UpdateSAMLConnectionRequest
 */
export class UpdateSAMLConnectionRequest extends Message<UpdateSAMLConnectionRequest> {
  /**
   * The ID of the SAML connection to update.
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * The updated SAML connection.
   *
   * @generated from field: ssoready.v1.SAMLConnection saml_connection = 2;
   */
  samlConnection?: SAMLConnection;

  constructor(data?: PartialMessage<UpdateSAMLConnectionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.UpdateSAMLConnectionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "saml_connection", kind: "message", T: SAMLConnection },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateSAMLConnectionRequest {
    return new UpdateSAMLConnectionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateSAMLConnectionRequest {
    return new UpdateSAMLConnectionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateSAMLConnectionRequest {
    return new UpdateSAMLConnectionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateSAMLConnectionRequest | PlainMessage<UpdateSAMLConnectionRequest> | undefined, b: UpdateSAMLConnectionRequest | PlainMessage<UpdateSAMLConnectionRequest> | undefined): boolean {
    return proto3.util.equals(UpdateSAMLConnectionRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.UpdateSAMLConnectionResponse
 */
export class UpdateSAMLConnectionResponse extends Message<UpdateSAMLConnectionResponse> {
  /**
   * The updated SAML connection.
   *
   * @generated from field: ssoready.v1.SAMLConnection saml_connection = 1;
   */
  samlConnection?: SAMLConnection;

  constructor(data?: PartialMessage<UpdateSAMLConnectionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.UpdateSAMLConnectionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "saml_connection", kind: "message", T: SAMLConnection },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateSAMLConnectionResponse {
    return new UpdateSAMLConnectionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateSAMLConnectionResponse {
    return new UpdateSAMLConnectionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateSAMLConnectionResponse {
    return new UpdateSAMLConnectionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateSAMLConnectionResponse | PlainMessage<UpdateSAMLConnectionResponse> | undefined, b: UpdateSAMLConnectionResponse | PlainMessage<UpdateSAMLConnectionResponse> | undefined): boolean {
    return proto3.util.equals(UpdateSAMLConnectionResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.ListSCIMDirectoriesRequest
 */
export class ListSCIMDirectoriesRequest extends Message<ListSCIMDirectoriesRequest> {
  /**
   * The organization the SCIM directories belong to.
   *
   * @generated from field: string organization_id = 1;
   */
  organizationId = "";

  /**
   * Pagination token. Leave empty to get the first page of results.
   *
   * @generated from field: string page_token = 2;
   */
  pageToken = "";

  constructor(data?: PartialMessage<ListSCIMDirectoriesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.ListSCIMDirectoriesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "organization_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListSCIMDirectoriesRequest {
    return new ListSCIMDirectoriesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListSCIMDirectoriesRequest {
    return new ListSCIMDirectoriesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListSCIMDirectoriesRequest {
    return new ListSCIMDirectoriesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListSCIMDirectoriesRequest | PlainMessage<ListSCIMDirectoriesRequest> | undefined, b: ListSCIMDirectoriesRequest | PlainMessage<ListSCIMDirectoriesRequest> | undefined): boolean {
    return proto3.util.equals(ListSCIMDirectoriesRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.ListSCIMDirectoriesResponse
 */
export class ListSCIMDirectoriesResponse extends Message<ListSCIMDirectoriesResponse> {
  /**
   * The list of SCIM directories.
   *
   * @generated from field: repeated ssoready.v1.SCIMDirectory scim_directories = 1;
   */
  scimDirectories: SCIMDirectory[] = [];

  /**
   * Value to use as `pageToken` for the next page of data. Empty if there is no more data.
   *
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  constructor(data?: PartialMessage<ListSCIMDirectoriesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.ListSCIMDirectoriesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "scim_directories", kind: "message", T: SCIMDirectory, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListSCIMDirectoriesResponse {
    return new ListSCIMDirectoriesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListSCIMDirectoriesResponse {
    return new ListSCIMDirectoriesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListSCIMDirectoriesResponse {
    return new ListSCIMDirectoriesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListSCIMDirectoriesResponse | PlainMessage<ListSCIMDirectoriesResponse> | undefined, b: ListSCIMDirectoriesResponse | PlainMessage<ListSCIMDirectoriesResponse> | undefined): boolean {
    return proto3.util.equals(ListSCIMDirectoriesResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.GetSCIMDirectoryRequest
 */
export class GetSCIMDirectoryRequest extends Message<GetSCIMDirectoryRequest> {
  /**
   * The ID of the SCIM directory.
   *
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<GetSCIMDirectoryRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.GetSCIMDirectoryRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSCIMDirectoryRequest {
    return new GetSCIMDirectoryRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSCIMDirectoryRequest {
    return new GetSCIMDirectoryRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSCIMDirectoryRequest {
    return new GetSCIMDirectoryRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetSCIMDirectoryRequest | PlainMessage<GetSCIMDirectoryRequest> | undefined, b: GetSCIMDirectoryRequest | PlainMessage<GetSCIMDirectoryRequest> | undefined): boolean {
    return proto3.util.equals(GetSCIMDirectoryRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.GetSCIMDirectoryResponse
 */
export class GetSCIMDirectoryResponse extends Message<GetSCIMDirectoryResponse> {
  /**
   * The requested SCIM directory.
   *
   * @generated from field: ssoready.v1.SCIMDirectory scim_directory = 1;
   */
  scimDirectory?: SCIMDirectory;

  constructor(data?: PartialMessage<GetSCIMDirectoryResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.GetSCIMDirectoryResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "scim_directory", kind: "message", T: SCIMDirectory },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSCIMDirectoryResponse {
    return new GetSCIMDirectoryResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSCIMDirectoryResponse {
    return new GetSCIMDirectoryResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSCIMDirectoryResponse {
    return new GetSCIMDirectoryResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetSCIMDirectoryResponse | PlainMessage<GetSCIMDirectoryResponse> | undefined, b: GetSCIMDirectoryResponse | PlainMessage<GetSCIMDirectoryResponse> | undefined): boolean {
    return proto3.util.equals(GetSCIMDirectoryResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.CreateSCIMDirectoryRequest
 */
export class CreateSCIMDirectoryRequest extends Message<CreateSCIMDirectoryRequest> {
  /**
   * The SCIM directory to create.
   *
   * @generated from field: ssoready.v1.SCIMDirectory scim_directory = 1;
   */
  scimDirectory?: SCIMDirectory;

  constructor(data?: PartialMessage<CreateSCIMDirectoryRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.CreateSCIMDirectoryRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "scim_directory", kind: "message", T: SCIMDirectory },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateSCIMDirectoryRequest {
    return new CreateSCIMDirectoryRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateSCIMDirectoryRequest {
    return new CreateSCIMDirectoryRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateSCIMDirectoryRequest {
    return new CreateSCIMDirectoryRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateSCIMDirectoryRequest | PlainMessage<CreateSCIMDirectoryRequest> | undefined, b: CreateSCIMDirectoryRequest | PlainMessage<CreateSCIMDirectoryRequest> | undefined): boolean {
    return proto3.util.equals(CreateSCIMDirectoryRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.CreateSCIMDirectoryResponse
 */
export class CreateSCIMDirectoryResponse extends Message<CreateSCIMDirectoryResponse> {
  /**
   * The updated SCIM directory.
   *
   * @generated from field: ssoready.v1.SCIMDirectory scim_directory = 1;
   */
  scimDirectory?: SCIMDirectory;

  constructor(data?: PartialMessage<CreateSCIMDirectoryResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.CreateSCIMDirectoryResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "scim_directory", kind: "message", T: SCIMDirectory },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateSCIMDirectoryResponse {
    return new CreateSCIMDirectoryResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateSCIMDirectoryResponse {
    return new CreateSCIMDirectoryResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateSCIMDirectoryResponse {
    return new CreateSCIMDirectoryResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateSCIMDirectoryResponse | PlainMessage<CreateSCIMDirectoryResponse> | undefined, b: CreateSCIMDirectoryResponse | PlainMessage<CreateSCIMDirectoryResponse> | undefined): boolean {
    return proto3.util.equals(CreateSCIMDirectoryResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.UpdateSCIMDirectoryRequest
 */
export class UpdateSCIMDirectoryRequest extends Message<UpdateSCIMDirectoryRequest> {
  /**
   * The ID of the SCIM directory to update.
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * The updated SCIM directory.
   *
   * @generated from field: ssoready.v1.SCIMDirectory scim_directory = 2;
   */
  scimDirectory?: SCIMDirectory;

  constructor(data?: PartialMessage<UpdateSCIMDirectoryRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.UpdateSCIMDirectoryRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "scim_directory", kind: "message", T: SCIMDirectory },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateSCIMDirectoryRequest {
    return new UpdateSCIMDirectoryRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateSCIMDirectoryRequest {
    return new UpdateSCIMDirectoryRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateSCIMDirectoryRequest {
    return new UpdateSCIMDirectoryRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateSCIMDirectoryRequest | PlainMessage<UpdateSCIMDirectoryRequest> | undefined, b: UpdateSCIMDirectoryRequest | PlainMessage<UpdateSCIMDirectoryRequest> | undefined): boolean {
    return proto3.util.equals(UpdateSCIMDirectoryRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.UpdateSCIMDirectoryResponse
 */
export class UpdateSCIMDirectoryResponse extends Message<UpdateSCIMDirectoryResponse> {
  /**
   * The updated SCIM directory.
   *
   * @generated from field: ssoready.v1.SCIMDirectory scim_directory = 1;
   */
  scimDirectory?: SCIMDirectory;

  constructor(data?: PartialMessage<UpdateSCIMDirectoryResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.UpdateSCIMDirectoryResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "scim_directory", kind: "message", T: SCIMDirectory },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateSCIMDirectoryResponse {
    return new UpdateSCIMDirectoryResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateSCIMDirectoryResponse {
    return new UpdateSCIMDirectoryResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateSCIMDirectoryResponse {
    return new UpdateSCIMDirectoryResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateSCIMDirectoryResponse | PlainMessage<UpdateSCIMDirectoryResponse> | undefined, b: UpdateSCIMDirectoryResponse | PlainMessage<UpdateSCIMDirectoryResponse> | undefined): boolean {
    return proto3.util.equals(UpdateSCIMDirectoryResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.RotateSCIMDirectoryBearerTokenRequest
 */
export class RotateSCIMDirectoryBearerTokenRequest extends Message<RotateSCIMDirectoryBearerTokenRequest> {
  /**
   * The ID of the SCIM directory whose bearer token to rotate.
   *
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<RotateSCIMDirectoryBearerTokenRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.RotateSCIMDirectoryBearerTokenRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RotateSCIMDirectoryBearerTokenRequest {
    return new RotateSCIMDirectoryBearerTokenRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RotateSCIMDirectoryBearerTokenRequest {
    return new RotateSCIMDirectoryBearerTokenRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RotateSCIMDirectoryBearerTokenRequest {
    return new RotateSCIMDirectoryBearerTokenRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RotateSCIMDirectoryBearerTokenRequest | PlainMessage<RotateSCIMDirectoryBearerTokenRequest> | undefined, b: RotateSCIMDirectoryBearerTokenRequest | PlainMessage<RotateSCIMDirectoryBearerTokenRequest> | undefined): boolean {
    return proto3.util.equals(RotateSCIMDirectoryBearerTokenRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.RotateSCIMDirectoryBearerTokenResponse
 */
export class RotateSCIMDirectoryBearerTokenResponse extends Message<RotateSCIMDirectoryBearerTokenResponse> {
  /**
   * The new, updated bearer token.
   *
   * Do not log or store this bearer token. It is an authentication token that your customer should securely input into
   * their Identity Provider.
   *
   * @generated from field: string bearer_token = 1;
   */
  bearerToken = "";

  constructor(data?: PartialMessage<RotateSCIMDirectoryBearerTokenResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.RotateSCIMDirectoryBearerTokenResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "bearer_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RotateSCIMDirectoryBearerTokenResponse {
    return new RotateSCIMDirectoryBearerTokenResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RotateSCIMDirectoryBearerTokenResponse {
    return new RotateSCIMDirectoryBearerTokenResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RotateSCIMDirectoryBearerTokenResponse {
    return new RotateSCIMDirectoryBearerTokenResponse().fromJsonString(jsonString, options);
  }

  static equals(a: RotateSCIMDirectoryBearerTokenResponse | PlainMessage<RotateSCIMDirectoryBearerTokenResponse> | undefined, b: RotateSCIMDirectoryBearerTokenResponse | PlainMessage<RotateSCIMDirectoryBearerTokenResponse> | undefined): boolean {
    return proto3.util.equals(RotateSCIMDirectoryBearerTokenResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.VerifyEmailRequest
 */
export class VerifyEmailRequest extends Message<VerifyEmailRequest> {
  /**
   * @generated from field: string email = 1;
   */
  email = "";

  constructor(data?: PartialMessage<VerifyEmailRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.VerifyEmailRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VerifyEmailRequest {
    return new VerifyEmailRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VerifyEmailRequest {
    return new VerifyEmailRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VerifyEmailRequest {
    return new VerifyEmailRequest().fromJsonString(jsonString, options);
  }

  static equals(a: VerifyEmailRequest | PlainMessage<VerifyEmailRequest> | undefined, b: VerifyEmailRequest | PlainMessage<VerifyEmailRequest> | undefined): boolean {
    return proto3.util.equals(VerifyEmailRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.SignInRequest
 */
export class SignInRequest extends Message<SignInRequest> {
  /**
   * @generated from field: string google_credential = 1;
   */
  googleCredential = "";

  /**
   * @generated from field: string email_verify_token = 2;
   */
  emailVerifyToken = "";

  /**
   * @generated from field: string microsoft_code = 3;
   */
  microsoftCode = "";

  constructor(data?: PartialMessage<SignInRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.SignInRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "google_credential", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "email_verify_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "microsoft_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SignInRequest {
    return new SignInRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SignInRequest {
    return new SignInRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SignInRequest {
    return new SignInRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SignInRequest | PlainMessage<SignInRequest> | undefined, b: SignInRequest | PlainMessage<SignInRequest> | undefined): boolean {
    return proto3.util.equals(SignInRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.SignInResponse
 */
export class SignInResponse extends Message<SignInResponse> {
  /**
   * @generated from field: string session_token = 1;
   */
  sessionToken = "";

  constructor(data?: PartialMessage<SignInResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.SignInResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "session_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SignInResponse {
    return new SignInResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SignInResponse {
    return new SignInResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SignInResponse {
    return new SignInResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SignInResponse | PlainMessage<SignInResponse> | undefined, b: SignInResponse | PlainMessage<SignInResponse> | undefined): boolean {
    return proto3.util.equals(SignInResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.SignOutRequest
 */
export class SignOutRequest extends Message<SignOutRequest> {
  constructor(data?: PartialMessage<SignOutRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.SignOutRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SignOutRequest {
    return new SignOutRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SignOutRequest {
    return new SignOutRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SignOutRequest {
    return new SignOutRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SignOutRequest | PlainMessage<SignOutRequest> | undefined, b: SignOutRequest | PlainMessage<SignOutRequest> | undefined): boolean {
    return proto3.util.equals(SignOutRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.SignOutResponse
 */
export class SignOutResponse extends Message<SignOutResponse> {
  constructor(data?: PartialMessage<SignOutResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.SignOutResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SignOutResponse {
    return new SignOutResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SignOutResponse {
    return new SignOutResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SignOutResponse {
    return new SignOutResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SignOutResponse | PlainMessage<SignOutResponse> | undefined, b: SignOutResponse | PlainMessage<SignOutResponse> | undefined): boolean {
    return proto3.util.equals(SignOutResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.WhoamiRequest
 */
export class WhoamiRequest extends Message<WhoamiRequest> {
  constructor(data?: PartialMessage<WhoamiRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.WhoamiRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WhoamiRequest {
    return new WhoamiRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WhoamiRequest {
    return new WhoamiRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WhoamiRequest {
    return new WhoamiRequest().fromJsonString(jsonString, options);
  }

  static equals(a: WhoamiRequest | PlainMessage<WhoamiRequest> | undefined, b: WhoamiRequest | PlainMessage<WhoamiRequest> | undefined): boolean {
    return proto3.util.equals(WhoamiRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.WhoamiResponse
 */
export class WhoamiResponse extends Message<WhoamiResponse> {
  /**
   * @generated from field: string app_user_id = 1;
   */
  appUserId = "";

  /**
   * @generated from field: string display_name = 2;
   */
  displayName = "";

  /**
   * @generated from field: string email = 3;
   */
  email = "";

  constructor(data?: PartialMessage<WhoamiResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.WhoamiResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "app_user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WhoamiResponse {
    return new WhoamiResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WhoamiResponse {
    return new WhoamiResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WhoamiResponse {
    return new WhoamiResponse().fromJsonString(jsonString, options);
  }

  static equals(a: WhoamiResponse | PlainMessage<WhoamiResponse> | undefined, b: WhoamiResponse | PlainMessage<WhoamiResponse> | undefined): boolean {
    return proto3.util.equals(WhoamiResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.GetOnboardingStateRequest
 */
export class GetOnboardingStateRequest extends Message<GetOnboardingStateRequest> {
  constructor(data?: PartialMessage<GetOnboardingStateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.GetOnboardingStateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOnboardingStateRequest {
    return new GetOnboardingStateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOnboardingStateRequest {
    return new GetOnboardingStateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetOnboardingStateRequest {
    return new GetOnboardingStateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetOnboardingStateRequest | PlainMessage<GetOnboardingStateRequest> | undefined, b: GetOnboardingStateRequest | PlainMessage<GetOnboardingStateRequest> | undefined): boolean {
    return proto3.util.equals(GetOnboardingStateRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.GetOnboardingStateResponse
 */
export class GetOnboardingStateResponse extends Message<GetOnboardingStateResponse> {
  /**
   * @generated from field: string dummyidp_app_id = 1;
   */
  dummyidpAppId = "";

  /**
   * @generated from field: string onboarding_environment_id = 2;
   */
  onboardingEnvironmentId = "";

  /**
   * @generated from field: string onboarding_organization_id = 3;
   */
  onboardingOrganizationId = "";

  /**
   * @generated from field: string onboarding_saml_connection_id = 4;
   */
  onboardingSamlConnectionId = "";

  constructor(data?: PartialMessage<GetOnboardingStateResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.GetOnboardingStateResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dummyidp_app_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "onboarding_environment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "onboarding_organization_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "onboarding_saml_connection_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOnboardingStateResponse {
    return new GetOnboardingStateResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOnboardingStateResponse {
    return new GetOnboardingStateResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetOnboardingStateResponse {
    return new GetOnboardingStateResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetOnboardingStateResponse | PlainMessage<GetOnboardingStateResponse> | undefined, b: GetOnboardingStateResponse | PlainMessage<GetOnboardingStateResponse> | undefined): boolean {
    return proto3.util.equals(GetOnboardingStateResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.UpdateOnboardingStateRequest
 */
export class UpdateOnboardingStateRequest extends Message<UpdateOnboardingStateRequest> {
  /**
   * @generated from field: string dummyidp_app_id = 1;
   */
  dummyidpAppId = "";

  /**
   * @generated from field: string onboarding_environment_id = 2;
   */
  onboardingEnvironmentId = "";

  /**
   * @generated from field: string onboarding_organization_id = 3;
   */
  onboardingOrganizationId = "";

  /**
   * @generated from field: string onboarding_saml_connection_id = 4;
   */
  onboardingSamlConnectionId = "";

  constructor(data?: PartialMessage<UpdateOnboardingStateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.UpdateOnboardingStateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dummyidp_app_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "onboarding_environment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "onboarding_organization_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "onboarding_saml_connection_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateOnboardingStateRequest {
    return new UpdateOnboardingStateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateOnboardingStateRequest {
    return new UpdateOnboardingStateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateOnboardingStateRequest {
    return new UpdateOnboardingStateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateOnboardingStateRequest | PlainMessage<UpdateOnboardingStateRequest> | undefined, b: UpdateOnboardingStateRequest | PlainMessage<UpdateOnboardingStateRequest> | undefined): boolean {
    return proto3.util.equals(UpdateOnboardingStateRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.OnboardingGetSAMLRedirectURLRequest
 */
export class OnboardingGetSAMLRedirectURLRequest extends Message<OnboardingGetSAMLRedirectURLRequest> {
  /**
   * @generated from field: string api_key_secret_token = 1;
   */
  apiKeySecretToken = "";

  /**
   * @generated from field: string saml_connection_id = 2;
   */
  samlConnectionId = "";

  constructor(data?: PartialMessage<OnboardingGetSAMLRedirectURLRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.OnboardingGetSAMLRedirectURLRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "api_key_secret_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "saml_connection_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OnboardingGetSAMLRedirectURLRequest {
    return new OnboardingGetSAMLRedirectURLRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OnboardingGetSAMLRedirectURLRequest {
    return new OnboardingGetSAMLRedirectURLRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OnboardingGetSAMLRedirectURLRequest {
    return new OnboardingGetSAMLRedirectURLRequest().fromJsonString(jsonString, options);
  }

  static equals(a: OnboardingGetSAMLRedirectURLRequest | PlainMessage<OnboardingGetSAMLRedirectURLRequest> | undefined, b: OnboardingGetSAMLRedirectURLRequest | PlainMessage<OnboardingGetSAMLRedirectURLRequest> | undefined): boolean {
    return proto3.util.equals(OnboardingGetSAMLRedirectURLRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.OnboardingRedeemSAMLAccessCodeRequest
 */
export class OnboardingRedeemSAMLAccessCodeRequest extends Message<OnboardingRedeemSAMLAccessCodeRequest> {
  /**
   * @generated from field: string api_key_secret_token = 1;
   */
  apiKeySecretToken = "";

  /**
   * @generated from field: string saml_access_code = 2;
   */
  samlAccessCode = "";

  constructor(data?: PartialMessage<OnboardingRedeemSAMLAccessCodeRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.OnboardingRedeemSAMLAccessCodeRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "api_key_secret_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "saml_access_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OnboardingRedeemSAMLAccessCodeRequest {
    return new OnboardingRedeemSAMLAccessCodeRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OnboardingRedeemSAMLAccessCodeRequest {
    return new OnboardingRedeemSAMLAccessCodeRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OnboardingRedeemSAMLAccessCodeRequest {
    return new OnboardingRedeemSAMLAccessCodeRequest().fromJsonString(jsonString, options);
  }

  static equals(a: OnboardingRedeemSAMLAccessCodeRequest | PlainMessage<OnboardingRedeemSAMLAccessCodeRequest> | undefined, b: OnboardingRedeemSAMLAccessCodeRequest | PlainMessage<OnboardingRedeemSAMLAccessCodeRequest> | undefined): boolean {
    return proto3.util.equals(OnboardingRedeemSAMLAccessCodeRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.GetAppOrganizationRequest
 */
export class GetAppOrganizationRequest extends Message<GetAppOrganizationRequest> {
  constructor(data?: PartialMessage<GetAppOrganizationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.GetAppOrganizationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAppOrganizationRequest {
    return new GetAppOrganizationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAppOrganizationRequest {
    return new GetAppOrganizationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAppOrganizationRequest {
    return new GetAppOrganizationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetAppOrganizationRequest | PlainMessage<GetAppOrganizationRequest> | undefined, b: GetAppOrganizationRequest | PlainMessage<GetAppOrganizationRequest> | undefined): boolean {
    return proto3.util.equals(GetAppOrganizationRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.GetAppOrganizationResponse
 */
export class GetAppOrganizationResponse extends Message<GetAppOrganizationResponse> {
  /**
   * @generated from field: string google_hosted_domain = 1;
   */
  googleHostedDomain = "";

  /**
   * @generated from field: bool entitled_management_api = 2;
   */
  entitledManagementApi = false;

  /**
   * @generated from field: bool entitled_custom_domains = 4;
   */
  entitledCustomDomains = false;

  constructor(data?: PartialMessage<GetAppOrganizationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.GetAppOrganizationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "google_hosted_domain", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "entitled_management_api", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "entitled_custom_domains", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAppOrganizationResponse {
    return new GetAppOrganizationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAppOrganizationResponse {
    return new GetAppOrganizationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAppOrganizationResponse {
    return new GetAppOrganizationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetAppOrganizationResponse | PlainMessage<GetAppOrganizationResponse> | undefined, b: GetAppOrganizationResponse | PlainMessage<GetAppOrganizationResponse> | undefined): boolean {
    return proto3.util.equals(GetAppOrganizationResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.ListAppUsersRequest
 */
export class ListAppUsersRequest extends Message<ListAppUsersRequest> {
  constructor(data?: PartialMessage<ListAppUsersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.ListAppUsersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAppUsersRequest {
    return new ListAppUsersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAppUsersRequest {
    return new ListAppUsersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAppUsersRequest {
    return new ListAppUsersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListAppUsersRequest | PlainMessage<ListAppUsersRequest> | undefined, b: ListAppUsersRequest | PlainMessage<ListAppUsersRequest> | undefined): boolean {
    return proto3.util.equals(ListAppUsersRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.ListAppUsersResponse
 */
export class ListAppUsersResponse extends Message<ListAppUsersResponse> {
  /**
   * @generated from field: repeated ssoready.v1.AppUser app_users = 1;
   */
  appUsers: AppUser[] = [];

  constructor(data?: PartialMessage<ListAppUsersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.ListAppUsersResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "app_users", kind: "message", T: AppUser, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAppUsersResponse {
    return new ListAppUsersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAppUsersResponse {
    return new ListAppUsersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAppUsersResponse {
    return new ListAppUsersResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListAppUsersResponse | PlainMessage<ListAppUsersResponse> | undefined, b: ListAppUsersResponse | PlainMessage<ListAppUsersResponse> | undefined): boolean {
    return proto3.util.equals(ListAppUsersResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.ListEnvironmentsRequest
 */
export class ListEnvironmentsRequest extends Message<ListEnvironmentsRequest> {
  /**
   * @generated from field: string page_token = 1;
   */
  pageToken = "";

  constructor(data?: PartialMessage<ListEnvironmentsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.ListEnvironmentsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListEnvironmentsRequest {
    return new ListEnvironmentsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListEnvironmentsRequest {
    return new ListEnvironmentsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListEnvironmentsRequest {
    return new ListEnvironmentsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListEnvironmentsRequest | PlainMessage<ListEnvironmentsRequest> | undefined, b: ListEnvironmentsRequest | PlainMessage<ListEnvironmentsRequest> | undefined): boolean {
    return proto3.util.equals(ListEnvironmentsRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.ListEnvironmentsResponse
 */
export class ListEnvironmentsResponse extends Message<ListEnvironmentsResponse> {
  /**
   * @generated from field: repeated ssoready.v1.Environment environments = 1;
   */
  environments: Environment[] = [];

  /**
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  constructor(data?: PartialMessage<ListEnvironmentsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.ListEnvironmentsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "environments", kind: "message", T: Environment, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListEnvironmentsResponse {
    return new ListEnvironmentsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListEnvironmentsResponse {
    return new ListEnvironmentsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListEnvironmentsResponse {
    return new ListEnvironmentsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListEnvironmentsResponse | PlainMessage<ListEnvironmentsResponse> | undefined, b: ListEnvironmentsResponse | PlainMessage<ListEnvironmentsResponse> | undefined): boolean {
    return proto3.util.equals(ListEnvironmentsResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.GetEnvironmentRequest
 */
export class GetEnvironmentRequest extends Message<GetEnvironmentRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<GetEnvironmentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.GetEnvironmentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetEnvironmentRequest {
    return new GetEnvironmentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetEnvironmentRequest {
    return new GetEnvironmentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetEnvironmentRequest {
    return new GetEnvironmentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetEnvironmentRequest | PlainMessage<GetEnvironmentRequest> | undefined, b: GetEnvironmentRequest | PlainMessage<GetEnvironmentRequest> | undefined): boolean {
    return proto3.util.equals(GetEnvironmentRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.CreateEnvironmentRequest
 */
export class CreateEnvironmentRequest extends Message<CreateEnvironmentRequest> {
  /**
   * @generated from field: ssoready.v1.Environment environment = 1;
   */
  environment?: Environment;

  constructor(data?: PartialMessage<CreateEnvironmentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.CreateEnvironmentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "environment", kind: "message", T: Environment },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateEnvironmentRequest {
    return new CreateEnvironmentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateEnvironmentRequest {
    return new CreateEnvironmentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateEnvironmentRequest {
    return new CreateEnvironmentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateEnvironmentRequest | PlainMessage<CreateEnvironmentRequest> | undefined, b: CreateEnvironmentRequest | PlainMessage<CreateEnvironmentRequest> | undefined): boolean {
    return proto3.util.equals(CreateEnvironmentRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.UpdateEnvironmentRequest
 */
export class UpdateEnvironmentRequest extends Message<UpdateEnvironmentRequest> {
  /**
   * @generated from field: ssoready.v1.Environment environment = 1;
   */
  environment?: Environment;

  constructor(data?: PartialMessage<UpdateEnvironmentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.UpdateEnvironmentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "environment", kind: "message", T: Environment },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateEnvironmentRequest {
    return new UpdateEnvironmentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateEnvironmentRequest {
    return new UpdateEnvironmentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateEnvironmentRequest {
    return new UpdateEnvironmentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateEnvironmentRequest | PlainMessage<UpdateEnvironmentRequest> | undefined, b: UpdateEnvironmentRequest | PlainMessage<UpdateEnvironmentRequest> | undefined): boolean {
    return proto3.util.equals(UpdateEnvironmentRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.GetEnvironmentCustomDomainSettingsRequest
 */
export class GetEnvironmentCustomDomainSettingsRequest extends Message<GetEnvironmentCustomDomainSettingsRequest> {
  /**
   * @generated from field: string environment_id = 1;
   */
  environmentId = "";

  constructor(data?: PartialMessage<GetEnvironmentCustomDomainSettingsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.GetEnvironmentCustomDomainSettingsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "environment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetEnvironmentCustomDomainSettingsRequest {
    return new GetEnvironmentCustomDomainSettingsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetEnvironmentCustomDomainSettingsRequest {
    return new GetEnvironmentCustomDomainSettingsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetEnvironmentCustomDomainSettingsRequest {
    return new GetEnvironmentCustomDomainSettingsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetEnvironmentCustomDomainSettingsRequest | PlainMessage<GetEnvironmentCustomDomainSettingsRequest> | undefined, b: GetEnvironmentCustomDomainSettingsRequest | PlainMessage<GetEnvironmentCustomDomainSettingsRequest> | undefined): boolean {
    return proto3.util.equals(GetEnvironmentCustomDomainSettingsRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.GetEnvironmentCustomDomainSettingsResponse
 */
export class GetEnvironmentCustomDomainSettingsResponse extends Message<GetEnvironmentCustomDomainSettingsResponse> {
  /**
   * @generated from field: string custom_auth_domain = 1;
   */
  customAuthDomain = "";

  /**
   * @generated from field: bool custom_auth_domain_configured = 2;
   */
  customAuthDomainConfigured = false;

  /**
   * @generated from field: string custom_auth_domain_cname_value = 3;
   */
  customAuthDomainCnameValue = "";

  /**
   * @generated from field: string custom_admin_domain = 4;
   */
  customAdminDomain = "";

  /**
   * @generated from field: bool custom_admin_domain_configured = 5;
   */
  customAdminDomainConfigured = false;

  /**
   * @generated from field: string custom_admin_domain_cname_value = 6;
   */
  customAdminDomainCnameValue = "";

  constructor(data?: PartialMessage<GetEnvironmentCustomDomainSettingsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.GetEnvironmentCustomDomainSettingsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "custom_auth_domain", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "custom_auth_domain_configured", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "custom_auth_domain_cname_value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "custom_admin_domain", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "custom_admin_domain_configured", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "custom_admin_domain_cname_value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetEnvironmentCustomDomainSettingsResponse {
    return new GetEnvironmentCustomDomainSettingsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetEnvironmentCustomDomainSettingsResponse {
    return new GetEnvironmentCustomDomainSettingsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetEnvironmentCustomDomainSettingsResponse {
    return new GetEnvironmentCustomDomainSettingsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetEnvironmentCustomDomainSettingsResponse | PlainMessage<GetEnvironmentCustomDomainSettingsResponse> | undefined, b: GetEnvironmentCustomDomainSettingsResponse | PlainMessage<GetEnvironmentCustomDomainSettingsResponse> | undefined): boolean {
    return proto3.util.equals(GetEnvironmentCustomDomainSettingsResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.UpdateEnvironmentCustomDomainSettingsRequest
 */
export class UpdateEnvironmentCustomDomainSettingsRequest extends Message<UpdateEnvironmentCustomDomainSettingsRequest> {
  /**
   * @generated from field: string environment_id = 1;
   */
  environmentId = "";

  /**
   * @generated from field: string custom_auth_domain = 2;
   */
  customAuthDomain = "";

  /**
   * @generated from field: string custom_admin_domain = 3;
   */
  customAdminDomain = "";

  constructor(data?: PartialMessage<UpdateEnvironmentCustomDomainSettingsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.UpdateEnvironmentCustomDomainSettingsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "environment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "custom_auth_domain", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "custom_admin_domain", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateEnvironmentCustomDomainSettingsRequest {
    return new UpdateEnvironmentCustomDomainSettingsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateEnvironmentCustomDomainSettingsRequest {
    return new UpdateEnvironmentCustomDomainSettingsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateEnvironmentCustomDomainSettingsRequest {
    return new UpdateEnvironmentCustomDomainSettingsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateEnvironmentCustomDomainSettingsRequest | PlainMessage<UpdateEnvironmentCustomDomainSettingsRequest> | undefined, b: UpdateEnvironmentCustomDomainSettingsRequest | PlainMessage<UpdateEnvironmentCustomDomainSettingsRequest> | undefined): boolean {
    return proto3.util.equals(UpdateEnvironmentCustomDomainSettingsRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.UpdateEnvironmentCustomDomainSettingsResponse
 */
export class UpdateEnvironmentCustomDomainSettingsResponse extends Message<UpdateEnvironmentCustomDomainSettingsResponse> {
  constructor(data?: PartialMessage<UpdateEnvironmentCustomDomainSettingsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.UpdateEnvironmentCustomDomainSettingsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateEnvironmentCustomDomainSettingsResponse {
    return new UpdateEnvironmentCustomDomainSettingsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateEnvironmentCustomDomainSettingsResponse {
    return new UpdateEnvironmentCustomDomainSettingsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateEnvironmentCustomDomainSettingsResponse {
    return new UpdateEnvironmentCustomDomainSettingsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateEnvironmentCustomDomainSettingsResponse | PlainMessage<UpdateEnvironmentCustomDomainSettingsResponse> | undefined, b: UpdateEnvironmentCustomDomainSettingsResponse | PlainMessage<UpdateEnvironmentCustomDomainSettingsResponse> | undefined): boolean {
    return proto3.util.equals(UpdateEnvironmentCustomDomainSettingsResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.CheckEnvironmentCustomDomainSettingsCertificatesRequest
 */
export class CheckEnvironmentCustomDomainSettingsCertificatesRequest extends Message<CheckEnvironmentCustomDomainSettingsCertificatesRequest> {
  /**
   * @generated from field: string environment_id = 1;
   */
  environmentId = "";

  constructor(data?: PartialMessage<CheckEnvironmentCustomDomainSettingsCertificatesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.CheckEnvironmentCustomDomainSettingsCertificatesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "environment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CheckEnvironmentCustomDomainSettingsCertificatesRequest {
    return new CheckEnvironmentCustomDomainSettingsCertificatesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CheckEnvironmentCustomDomainSettingsCertificatesRequest {
    return new CheckEnvironmentCustomDomainSettingsCertificatesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CheckEnvironmentCustomDomainSettingsCertificatesRequest {
    return new CheckEnvironmentCustomDomainSettingsCertificatesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CheckEnvironmentCustomDomainSettingsCertificatesRequest | PlainMessage<CheckEnvironmentCustomDomainSettingsCertificatesRequest> | undefined, b: CheckEnvironmentCustomDomainSettingsCertificatesRequest | PlainMessage<CheckEnvironmentCustomDomainSettingsCertificatesRequest> | undefined): boolean {
    return proto3.util.equals(CheckEnvironmentCustomDomainSettingsCertificatesRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.CheckEnvironmentCustomDomainSettingsCertificatesResponse
 */
export class CheckEnvironmentCustomDomainSettingsCertificatesResponse extends Message<CheckEnvironmentCustomDomainSettingsCertificatesResponse> {
  /**
   * @generated from field: bool custom_auth_domain_configured = 1;
   */
  customAuthDomainConfigured = false;

  /**
   * @generated from field: bool custom_admin_domain_configured = 2;
   */
  customAdminDomainConfigured = false;

  constructor(data?: PartialMessage<CheckEnvironmentCustomDomainSettingsCertificatesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.CheckEnvironmentCustomDomainSettingsCertificatesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "custom_auth_domain_configured", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "custom_admin_domain_configured", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CheckEnvironmentCustomDomainSettingsCertificatesResponse {
    return new CheckEnvironmentCustomDomainSettingsCertificatesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CheckEnvironmentCustomDomainSettingsCertificatesResponse {
    return new CheckEnvironmentCustomDomainSettingsCertificatesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CheckEnvironmentCustomDomainSettingsCertificatesResponse {
    return new CheckEnvironmentCustomDomainSettingsCertificatesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CheckEnvironmentCustomDomainSettingsCertificatesResponse | PlainMessage<CheckEnvironmentCustomDomainSettingsCertificatesResponse> | undefined, b: CheckEnvironmentCustomDomainSettingsCertificatesResponse | PlainMessage<CheckEnvironmentCustomDomainSettingsCertificatesResponse> | undefined): boolean {
    return proto3.util.equals(CheckEnvironmentCustomDomainSettingsCertificatesResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.ListAPIKeysRequest
 */
export class ListAPIKeysRequest extends Message<ListAPIKeysRequest> {
  /**
   * @generated from field: string environment_id = 1;
   */
  environmentId = "";

  /**
   * @generated from field: string page_token = 2;
   */
  pageToken = "";

  constructor(data?: PartialMessage<ListAPIKeysRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.ListAPIKeysRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "environment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAPIKeysRequest {
    return new ListAPIKeysRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAPIKeysRequest {
    return new ListAPIKeysRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAPIKeysRequest {
    return new ListAPIKeysRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListAPIKeysRequest | PlainMessage<ListAPIKeysRequest> | undefined, b: ListAPIKeysRequest | PlainMessage<ListAPIKeysRequest> | undefined): boolean {
    return proto3.util.equals(ListAPIKeysRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.ListAPIKeysResponse
 */
export class ListAPIKeysResponse extends Message<ListAPIKeysResponse> {
  /**
   * @generated from field: repeated ssoready.v1.APIKey api_keys = 1;
   */
  apiKeys: APIKey[] = [];

  /**
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  constructor(data?: PartialMessage<ListAPIKeysResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.ListAPIKeysResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "api_keys", kind: "message", T: APIKey, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAPIKeysResponse {
    return new ListAPIKeysResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAPIKeysResponse {
    return new ListAPIKeysResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAPIKeysResponse {
    return new ListAPIKeysResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListAPIKeysResponse | PlainMessage<ListAPIKeysResponse> | undefined, b: ListAPIKeysResponse | PlainMessage<ListAPIKeysResponse> | undefined): boolean {
    return proto3.util.equals(ListAPIKeysResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.GetAPIKeyRequest
 */
export class GetAPIKeyRequest extends Message<GetAPIKeyRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<GetAPIKeyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.GetAPIKeyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAPIKeyRequest {
    return new GetAPIKeyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAPIKeyRequest {
    return new GetAPIKeyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAPIKeyRequest {
    return new GetAPIKeyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetAPIKeyRequest | PlainMessage<GetAPIKeyRequest> | undefined, b: GetAPIKeyRequest | PlainMessage<GetAPIKeyRequest> | undefined): boolean {
    return proto3.util.equals(GetAPIKeyRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.CreateAPIKeyRequest
 */
export class CreateAPIKeyRequest extends Message<CreateAPIKeyRequest> {
  /**
   * @generated from field: ssoready.v1.APIKey api_key = 1;
   */
  apiKey?: APIKey;

  constructor(data?: PartialMessage<CreateAPIKeyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.CreateAPIKeyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "api_key", kind: "message", T: APIKey },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateAPIKeyRequest {
    return new CreateAPIKeyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateAPIKeyRequest {
    return new CreateAPIKeyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateAPIKeyRequest {
    return new CreateAPIKeyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateAPIKeyRequest | PlainMessage<CreateAPIKeyRequest> | undefined, b: CreateAPIKeyRequest | PlainMessage<CreateAPIKeyRequest> | undefined): boolean {
    return proto3.util.equals(CreateAPIKeyRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.DeleteAPIKeyRequest
 */
export class DeleteAPIKeyRequest extends Message<DeleteAPIKeyRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<DeleteAPIKeyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.DeleteAPIKeyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteAPIKeyRequest {
    return new DeleteAPIKeyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteAPIKeyRequest {
    return new DeleteAPIKeyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteAPIKeyRequest {
    return new DeleteAPIKeyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteAPIKeyRequest | PlainMessage<DeleteAPIKeyRequest> | undefined, b: DeleteAPIKeyRequest | PlainMessage<DeleteAPIKeyRequest> | undefined): boolean {
    return proto3.util.equals(DeleteAPIKeyRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.ListSAMLOAuthClientsRequest
 */
export class ListSAMLOAuthClientsRequest extends Message<ListSAMLOAuthClientsRequest> {
  /**
   * @generated from field: string environment_id = 1;
   */
  environmentId = "";

  /**
   * @generated from field: string page_token = 2;
   */
  pageToken = "";

  constructor(data?: PartialMessage<ListSAMLOAuthClientsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.ListSAMLOAuthClientsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "environment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListSAMLOAuthClientsRequest {
    return new ListSAMLOAuthClientsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListSAMLOAuthClientsRequest {
    return new ListSAMLOAuthClientsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListSAMLOAuthClientsRequest {
    return new ListSAMLOAuthClientsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListSAMLOAuthClientsRequest | PlainMessage<ListSAMLOAuthClientsRequest> | undefined, b: ListSAMLOAuthClientsRequest | PlainMessage<ListSAMLOAuthClientsRequest> | undefined): boolean {
    return proto3.util.equals(ListSAMLOAuthClientsRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.ListSAMLOAuthClientsResponse
 */
export class ListSAMLOAuthClientsResponse extends Message<ListSAMLOAuthClientsResponse> {
  /**
   * @generated from field: repeated ssoready.v1.SAMLOAuthClient saml_oauth_clients = 1;
   */
  samlOauthClients: SAMLOAuthClient[] = [];

  /**
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  constructor(data?: PartialMessage<ListSAMLOAuthClientsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.ListSAMLOAuthClientsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "saml_oauth_clients", kind: "message", T: SAMLOAuthClient, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListSAMLOAuthClientsResponse {
    return new ListSAMLOAuthClientsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListSAMLOAuthClientsResponse {
    return new ListSAMLOAuthClientsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListSAMLOAuthClientsResponse {
    return new ListSAMLOAuthClientsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListSAMLOAuthClientsResponse | PlainMessage<ListSAMLOAuthClientsResponse> | undefined, b: ListSAMLOAuthClientsResponse | PlainMessage<ListSAMLOAuthClientsResponse> | undefined): boolean {
    return proto3.util.equals(ListSAMLOAuthClientsResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.GetSAMLOAuthClientRequest
 */
export class GetSAMLOAuthClientRequest extends Message<GetSAMLOAuthClientRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<GetSAMLOAuthClientRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.GetSAMLOAuthClientRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSAMLOAuthClientRequest {
    return new GetSAMLOAuthClientRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSAMLOAuthClientRequest {
    return new GetSAMLOAuthClientRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSAMLOAuthClientRequest {
    return new GetSAMLOAuthClientRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetSAMLOAuthClientRequest | PlainMessage<GetSAMLOAuthClientRequest> | undefined, b: GetSAMLOAuthClientRequest | PlainMessage<GetSAMLOAuthClientRequest> | undefined): boolean {
    return proto3.util.equals(GetSAMLOAuthClientRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.CreateSAMLOAuthClientRequest
 */
export class CreateSAMLOAuthClientRequest extends Message<CreateSAMLOAuthClientRequest> {
  /**
   * @generated from field: ssoready.v1.SAMLOAuthClient saml_oauth_client = 1;
   */
  samlOauthClient?: SAMLOAuthClient;

  constructor(data?: PartialMessage<CreateSAMLOAuthClientRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.CreateSAMLOAuthClientRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "saml_oauth_client", kind: "message", T: SAMLOAuthClient },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateSAMLOAuthClientRequest {
    return new CreateSAMLOAuthClientRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateSAMLOAuthClientRequest {
    return new CreateSAMLOAuthClientRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateSAMLOAuthClientRequest {
    return new CreateSAMLOAuthClientRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateSAMLOAuthClientRequest | PlainMessage<CreateSAMLOAuthClientRequest> | undefined, b: CreateSAMLOAuthClientRequest | PlainMessage<CreateSAMLOAuthClientRequest> | undefined): boolean {
    return proto3.util.equals(CreateSAMLOAuthClientRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.DeleteSAMLOAuthClientRequest
 */
export class DeleteSAMLOAuthClientRequest extends Message<DeleteSAMLOAuthClientRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<DeleteSAMLOAuthClientRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.DeleteSAMLOAuthClientRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteSAMLOAuthClientRequest {
    return new DeleteSAMLOAuthClientRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteSAMLOAuthClientRequest {
    return new DeleteSAMLOAuthClientRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteSAMLOAuthClientRequest {
    return new DeleteSAMLOAuthClientRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteSAMLOAuthClientRequest | PlainMessage<DeleteSAMLOAuthClientRequest> | undefined, b: DeleteSAMLOAuthClientRequest | PlainMessage<DeleteSAMLOAuthClientRequest> | undefined): boolean {
    return proto3.util.equals(DeleteSAMLOAuthClientRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AppListOrganizationsRequest
 */
export class AppListOrganizationsRequest extends Message<AppListOrganizationsRequest> {
  /**
   * @generated from field: string environment_id = 1;
   */
  environmentId = "";

  /**
   * @generated from field: string page_token = 2;
   */
  pageToken = "";

  constructor(data?: PartialMessage<AppListOrganizationsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AppListOrganizationsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "environment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AppListOrganizationsRequest {
    return new AppListOrganizationsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AppListOrganizationsRequest {
    return new AppListOrganizationsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AppListOrganizationsRequest {
    return new AppListOrganizationsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AppListOrganizationsRequest | PlainMessage<AppListOrganizationsRequest> | undefined, b: AppListOrganizationsRequest | PlainMessage<AppListOrganizationsRequest> | undefined): boolean {
    return proto3.util.equals(AppListOrganizationsRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AppListOrganizationsResponse
 */
export class AppListOrganizationsResponse extends Message<AppListOrganizationsResponse> {
  /**
   * @generated from field: repeated ssoready.v1.Organization organizations = 1;
   */
  organizations: Organization[] = [];

  /**
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  constructor(data?: PartialMessage<AppListOrganizationsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AppListOrganizationsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "organizations", kind: "message", T: Organization, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AppListOrganizationsResponse {
    return new AppListOrganizationsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AppListOrganizationsResponse {
    return new AppListOrganizationsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AppListOrganizationsResponse {
    return new AppListOrganizationsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AppListOrganizationsResponse | PlainMessage<AppListOrganizationsResponse> | undefined, b: AppListOrganizationsResponse | PlainMessage<AppListOrganizationsResponse> | undefined): boolean {
    return proto3.util.equals(AppListOrganizationsResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AppGetOrganizationRequest
 */
export class AppGetOrganizationRequest extends Message<AppGetOrganizationRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<AppGetOrganizationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AppGetOrganizationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AppGetOrganizationRequest {
    return new AppGetOrganizationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AppGetOrganizationRequest {
    return new AppGetOrganizationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AppGetOrganizationRequest {
    return new AppGetOrganizationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AppGetOrganizationRequest | PlainMessage<AppGetOrganizationRequest> | undefined, b: AppGetOrganizationRequest | PlainMessage<AppGetOrganizationRequest> | undefined): boolean {
    return proto3.util.equals(AppGetOrganizationRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AppCreateOrganizationRequest
 */
export class AppCreateOrganizationRequest extends Message<AppCreateOrganizationRequest> {
  /**
   * @generated from field: ssoready.v1.Organization organization = 1;
   */
  organization?: Organization;

  constructor(data?: PartialMessage<AppCreateOrganizationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AppCreateOrganizationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "organization", kind: "message", T: Organization },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AppCreateOrganizationRequest {
    return new AppCreateOrganizationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AppCreateOrganizationRequest {
    return new AppCreateOrganizationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AppCreateOrganizationRequest {
    return new AppCreateOrganizationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AppCreateOrganizationRequest | PlainMessage<AppCreateOrganizationRequest> | undefined, b: AppCreateOrganizationRequest | PlainMessage<AppCreateOrganizationRequest> | undefined): boolean {
    return proto3.util.equals(AppCreateOrganizationRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AppUpdateOrganizationRequest
 */
export class AppUpdateOrganizationRequest extends Message<AppUpdateOrganizationRequest> {
  /**
   * @generated from field: ssoready.v1.Organization organization = 1;
   */
  organization?: Organization;

  constructor(data?: PartialMessage<AppUpdateOrganizationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AppUpdateOrganizationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "organization", kind: "message", T: Organization },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AppUpdateOrganizationRequest {
    return new AppUpdateOrganizationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AppUpdateOrganizationRequest {
    return new AppUpdateOrganizationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AppUpdateOrganizationRequest {
    return new AppUpdateOrganizationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AppUpdateOrganizationRequest | PlainMessage<AppUpdateOrganizationRequest> | undefined, b: AppUpdateOrganizationRequest | PlainMessage<AppUpdateOrganizationRequest> | undefined): boolean {
    return proto3.util.equals(AppUpdateOrganizationRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AppGetAdminSettingsRequest
 */
export class AppGetAdminSettingsRequest extends Message<AppGetAdminSettingsRequest> {
  /**
   * @generated from field: string environment_id = 1;
   */
  environmentId = "";

  constructor(data?: PartialMessage<AppGetAdminSettingsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AppGetAdminSettingsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "environment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AppGetAdminSettingsRequest {
    return new AppGetAdminSettingsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AppGetAdminSettingsRequest {
    return new AppGetAdminSettingsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AppGetAdminSettingsRequest {
    return new AppGetAdminSettingsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AppGetAdminSettingsRequest | PlainMessage<AppGetAdminSettingsRequest> | undefined, b: AppGetAdminSettingsRequest | PlainMessage<AppGetAdminSettingsRequest> | undefined): boolean {
    return proto3.util.equals(AppGetAdminSettingsRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AppGetAdminSettingsResponse
 */
export class AppGetAdminSettingsResponse extends Message<AppGetAdminSettingsResponse> {
  /**
   * @generated from field: string admin_application_name = 1;
   */
  adminApplicationName = "";

  /**
   * @generated from field: string admin_return_url = 2;
   */
  adminReturnUrl = "";

  /**
   * @generated from field: string admin_logo_url = 3;
   */
  adminLogoUrl = "";

  constructor(data?: PartialMessage<AppGetAdminSettingsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AppGetAdminSettingsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "admin_application_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "admin_return_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "admin_logo_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AppGetAdminSettingsResponse {
    return new AppGetAdminSettingsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AppGetAdminSettingsResponse {
    return new AppGetAdminSettingsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AppGetAdminSettingsResponse {
    return new AppGetAdminSettingsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AppGetAdminSettingsResponse | PlainMessage<AppGetAdminSettingsResponse> | undefined, b: AppGetAdminSettingsResponse | PlainMessage<AppGetAdminSettingsResponse> | undefined): boolean {
    return proto3.util.equals(AppGetAdminSettingsResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AppUpdateAdminSettingsRequest
 */
export class AppUpdateAdminSettingsRequest extends Message<AppUpdateAdminSettingsRequest> {
  /**
   * @generated from field: string environment_id = 1;
   */
  environmentId = "";

  /**
   * @generated from field: string admin_application_name = 2;
   */
  adminApplicationName = "";

  /**
   * @generated from field: string admin_return_url = 3;
   */
  adminReturnUrl = "";

  constructor(data?: PartialMessage<AppUpdateAdminSettingsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AppUpdateAdminSettingsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "environment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "admin_application_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "admin_return_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AppUpdateAdminSettingsRequest {
    return new AppUpdateAdminSettingsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AppUpdateAdminSettingsRequest {
    return new AppUpdateAdminSettingsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AppUpdateAdminSettingsRequest {
    return new AppUpdateAdminSettingsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AppUpdateAdminSettingsRequest | PlainMessage<AppUpdateAdminSettingsRequest> | undefined, b: AppUpdateAdminSettingsRequest | PlainMessage<AppUpdateAdminSettingsRequest> | undefined): boolean {
    return proto3.util.equals(AppUpdateAdminSettingsRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AppUpdateAdminSettingsResponse
 */
export class AppUpdateAdminSettingsResponse extends Message<AppUpdateAdminSettingsResponse> {
  constructor(data?: PartialMessage<AppUpdateAdminSettingsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AppUpdateAdminSettingsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AppUpdateAdminSettingsResponse {
    return new AppUpdateAdminSettingsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AppUpdateAdminSettingsResponse {
    return new AppUpdateAdminSettingsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AppUpdateAdminSettingsResponse {
    return new AppUpdateAdminSettingsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AppUpdateAdminSettingsResponse | PlainMessage<AppUpdateAdminSettingsResponse> | undefined, b: AppUpdateAdminSettingsResponse | PlainMessage<AppUpdateAdminSettingsResponse> | undefined): boolean {
    return proto3.util.equals(AppUpdateAdminSettingsResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AppUpdateAdminSettingsLogoRequest
 */
export class AppUpdateAdminSettingsLogoRequest extends Message<AppUpdateAdminSettingsLogoRequest> {
  /**
   * @generated from field: string environment_id = 1;
   */
  environmentId = "";

  constructor(data?: PartialMessage<AppUpdateAdminSettingsLogoRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AppUpdateAdminSettingsLogoRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "environment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AppUpdateAdminSettingsLogoRequest {
    return new AppUpdateAdminSettingsLogoRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AppUpdateAdminSettingsLogoRequest {
    return new AppUpdateAdminSettingsLogoRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AppUpdateAdminSettingsLogoRequest {
    return new AppUpdateAdminSettingsLogoRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AppUpdateAdminSettingsLogoRequest | PlainMessage<AppUpdateAdminSettingsLogoRequest> | undefined, b: AppUpdateAdminSettingsLogoRequest | PlainMessage<AppUpdateAdminSettingsLogoRequest> | undefined): boolean {
    return proto3.util.equals(AppUpdateAdminSettingsLogoRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AppUpdateAdminSettingsLogoResponse
 */
export class AppUpdateAdminSettingsLogoResponse extends Message<AppUpdateAdminSettingsLogoResponse> {
  /**
   * @generated from field: string upload_url = 1;
   */
  uploadUrl = "";

  constructor(data?: PartialMessage<AppUpdateAdminSettingsLogoResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AppUpdateAdminSettingsLogoResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "upload_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AppUpdateAdminSettingsLogoResponse {
    return new AppUpdateAdminSettingsLogoResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AppUpdateAdminSettingsLogoResponse {
    return new AppUpdateAdminSettingsLogoResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AppUpdateAdminSettingsLogoResponse {
    return new AppUpdateAdminSettingsLogoResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AppUpdateAdminSettingsLogoResponse | PlainMessage<AppUpdateAdminSettingsLogoResponse> | undefined, b: AppUpdateAdminSettingsLogoResponse | PlainMessage<AppUpdateAdminSettingsLogoResponse> | undefined): boolean {
    return proto3.util.equals(AppUpdateAdminSettingsLogoResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AppCreateAdminSetupURLRequest
 */
export class AppCreateAdminSetupURLRequest extends Message<AppCreateAdminSetupURLRequest> {
  /**
   * @generated from field: string organization_id = 1;
   */
  organizationId = "";

  /**
   * @generated from field: bool can_manage_saml = 2;
   */
  canManageSaml = false;

  /**
   * @generated from field: bool can_manage_scim = 3;
   */
  canManageScim = false;

  constructor(data?: PartialMessage<AppCreateAdminSetupURLRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AppCreateAdminSetupURLRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "organization_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "can_manage_saml", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "can_manage_scim", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AppCreateAdminSetupURLRequest {
    return new AppCreateAdminSetupURLRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AppCreateAdminSetupURLRequest {
    return new AppCreateAdminSetupURLRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AppCreateAdminSetupURLRequest {
    return new AppCreateAdminSetupURLRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AppCreateAdminSetupURLRequest | PlainMessage<AppCreateAdminSetupURLRequest> | undefined, b: AppCreateAdminSetupURLRequest | PlainMessage<AppCreateAdminSetupURLRequest> | undefined): boolean {
    return proto3.util.equals(AppCreateAdminSetupURLRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AppCreateAdminSetupURLResponse
 */
export class AppCreateAdminSetupURLResponse extends Message<AppCreateAdminSetupURLResponse> {
  /**
   * @generated from field: string url = 1;
   */
  url = "";

  constructor(data?: PartialMessage<AppCreateAdminSetupURLResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AppCreateAdminSetupURLResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AppCreateAdminSetupURLResponse {
    return new AppCreateAdminSetupURLResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AppCreateAdminSetupURLResponse {
    return new AppCreateAdminSetupURLResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AppCreateAdminSetupURLResponse {
    return new AppCreateAdminSetupURLResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AppCreateAdminSetupURLResponse | PlainMessage<AppCreateAdminSetupURLResponse> | undefined, b: AppCreateAdminSetupURLResponse | PlainMessage<AppCreateAdminSetupURLResponse> | undefined): boolean {
    return proto3.util.equals(AppCreateAdminSetupURLResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AppListSAMLConnectionsRequest
 */
export class AppListSAMLConnectionsRequest extends Message<AppListSAMLConnectionsRequest> {
  /**
   * @generated from field: string organization_id = 1;
   */
  organizationId = "";

  /**
   * @generated from field: string page_token = 2;
   */
  pageToken = "";

  constructor(data?: PartialMessage<AppListSAMLConnectionsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AppListSAMLConnectionsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "organization_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AppListSAMLConnectionsRequest {
    return new AppListSAMLConnectionsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AppListSAMLConnectionsRequest {
    return new AppListSAMLConnectionsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AppListSAMLConnectionsRequest {
    return new AppListSAMLConnectionsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AppListSAMLConnectionsRequest | PlainMessage<AppListSAMLConnectionsRequest> | undefined, b: AppListSAMLConnectionsRequest | PlainMessage<AppListSAMLConnectionsRequest> | undefined): boolean {
    return proto3.util.equals(AppListSAMLConnectionsRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AppListSAMLConnectionsResponse
 */
export class AppListSAMLConnectionsResponse extends Message<AppListSAMLConnectionsResponse> {
  /**
   * @generated from field: repeated ssoready.v1.SAMLConnection saml_connections = 1;
   */
  samlConnections: SAMLConnection[] = [];

  /**
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  constructor(data?: PartialMessage<AppListSAMLConnectionsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AppListSAMLConnectionsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "saml_connections", kind: "message", T: SAMLConnection, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AppListSAMLConnectionsResponse {
    return new AppListSAMLConnectionsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AppListSAMLConnectionsResponse {
    return new AppListSAMLConnectionsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AppListSAMLConnectionsResponse {
    return new AppListSAMLConnectionsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AppListSAMLConnectionsResponse | PlainMessage<AppListSAMLConnectionsResponse> | undefined, b: AppListSAMLConnectionsResponse | PlainMessage<AppListSAMLConnectionsResponse> | undefined): boolean {
    return proto3.util.equals(AppListSAMLConnectionsResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AppGetSAMLConnectionRequest
 */
export class AppGetSAMLConnectionRequest extends Message<AppGetSAMLConnectionRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<AppGetSAMLConnectionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AppGetSAMLConnectionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AppGetSAMLConnectionRequest {
    return new AppGetSAMLConnectionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AppGetSAMLConnectionRequest {
    return new AppGetSAMLConnectionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AppGetSAMLConnectionRequest {
    return new AppGetSAMLConnectionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AppGetSAMLConnectionRequest | PlainMessage<AppGetSAMLConnectionRequest> | undefined, b: AppGetSAMLConnectionRequest | PlainMessage<AppGetSAMLConnectionRequest> | undefined): boolean {
    return proto3.util.equals(AppGetSAMLConnectionRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AppCreateSAMLConnectionRequest
 */
export class AppCreateSAMLConnectionRequest extends Message<AppCreateSAMLConnectionRequest> {
  /**
   * @generated from field: ssoready.v1.SAMLConnection saml_connection = 1;
   */
  samlConnection?: SAMLConnection;

  constructor(data?: PartialMessage<AppCreateSAMLConnectionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AppCreateSAMLConnectionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "saml_connection", kind: "message", T: SAMLConnection },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AppCreateSAMLConnectionRequest {
    return new AppCreateSAMLConnectionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AppCreateSAMLConnectionRequest {
    return new AppCreateSAMLConnectionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AppCreateSAMLConnectionRequest {
    return new AppCreateSAMLConnectionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AppCreateSAMLConnectionRequest | PlainMessage<AppCreateSAMLConnectionRequest> | undefined, b: AppCreateSAMLConnectionRequest | PlainMessage<AppCreateSAMLConnectionRequest> | undefined): boolean {
    return proto3.util.equals(AppCreateSAMLConnectionRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AppUpdateSAMLConnectionRequest
 */
export class AppUpdateSAMLConnectionRequest extends Message<AppUpdateSAMLConnectionRequest> {
  /**
   * @generated from field: ssoready.v1.SAMLConnection saml_connection = 1;
   */
  samlConnection?: SAMLConnection;

  constructor(data?: PartialMessage<AppUpdateSAMLConnectionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AppUpdateSAMLConnectionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "saml_connection", kind: "message", T: SAMLConnection },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AppUpdateSAMLConnectionRequest {
    return new AppUpdateSAMLConnectionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AppUpdateSAMLConnectionRequest {
    return new AppUpdateSAMLConnectionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AppUpdateSAMLConnectionRequest {
    return new AppUpdateSAMLConnectionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AppUpdateSAMLConnectionRequest | PlainMessage<AppUpdateSAMLConnectionRequest> | undefined, b: AppUpdateSAMLConnectionRequest | PlainMessage<AppUpdateSAMLConnectionRequest> | undefined): boolean {
    return proto3.util.equals(AppUpdateSAMLConnectionRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AppListSAMLFlowsRequest
 */
export class AppListSAMLFlowsRequest extends Message<AppListSAMLFlowsRequest> {
  /**
   * @generated from field: string saml_connection_id = 1;
   */
  samlConnectionId = "";

  /**
   * @generated from field: string page_token = 2;
   */
  pageToken = "";

  constructor(data?: PartialMessage<AppListSAMLFlowsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AppListSAMLFlowsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "saml_connection_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AppListSAMLFlowsRequest {
    return new AppListSAMLFlowsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AppListSAMLFlowsRequest {
    return new AppListSAMLFlowsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AppListSAMLFlowsRequest {
    return new AppListSAMLFlowsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AppListSAMLFlowsRequest | PlainMessage<AppListSAMLFlowsRequest> | undefined, b: AppListSAMLFlowsRequest | PlainMessage<AppListSAMLFlowsRequest> | undefined): boolean {
    return proto3.util.equals(AppListSAMLFlowsRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AppListSAMLFlowsResponse
 */
export class AppListSAMLFlowsResponse extends Message<AppListSAMLFlowsResponse> {
  /**
   * @generated from field: repeated ssoready.v1.SAMLFlow saml_flows = 1;
   */
  samlFlows: SAMLFlow[] = [];

  /**
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  constructor(data?: PartialMessage<AppListSAMLFlowsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AppListSAMLFlowsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "saml_flows", kind: "message", T: SAMLFlow, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AppListSAMLFlowsResponse {
    return new AppListSAMLFlowsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AppListSAMLFlowsResponse {
    return new AppListSAMLFlowsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AppListSAMLFlowsResponse {
    return new AppListSAMLFlowsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AppListSAMLFlowsResponse | PlainMessage<AppListSAMLFlowsResponse> | undefined, b: AppListSAMLFlowsResponse | PlainMessage<AppListSAMLFlowsResponse> | undefined): boolean {
    return proto3.util.equals(AppListSAMLFlowsResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AppGetSAMLFlowRequest
 */
export class AppGetSAMLFlowRequest extends Message<AppGetSAMLFlowRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<AppGetSAMLFlowRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AppGetSAMLFlowRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AppGetSAMLFlowRequest {
    return new AppGetSAMLFlowRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AppGetSAMLFlowRequest {
    return new AppGetSAMLFlowRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AppGetSAMLFlowRequest {
    return new AppGetSAMLFlowRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AppGetSAMLFlowRequest | PlainMessage<AppGetSAMLFlowRequest> | undefined, b: AppGetSAMLFlowRequest | PlainMessage<AppGetSAMLFlowRequest> | undefined): boolean {
    return proto3.util.equals(AppGetSAMLFlowRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.ParseSAMLMetadataRequest
 */
export class ParseSAMLMetadataRequest extends Message<ParseSAMLMetadataRequest> {
  /**
   * @generated from field: string url = 1;
   */
  url = "";

  constructor(data?: PartialMessage<ParseSAMLMetadataRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.ParseSAMLMetadataRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ParseSAMLMetadataRequest {
    return new ParseSAMLMetadataRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ParseSAMLMetadataRequest {
    return new ParseSAMLMetadataRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ParseSAMLMetadataRequest {
    return new ParseSAMLMetadataRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ParseSAMLMetadataRequest | PlainMessage<ParseSAMLMetadataRequest> | undefined, b: ParseSAMLMetadataRequest | PlainMessage<ParseSAMLMetadataRequest> | undefined): boolean {
    return proto3.util.equals(ParseSAMLMetadataRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.ParseSAMLMetadataResponse
 */
export class ParseSAMLMetadataResponse extends Message<ParseSAMLMetadataResponse> {
  /**
   * @generated from field: string idp_redirect_url = 1;
   */
  idpRedirectUrl = "";

  /**
   * @generated from field: string idp_certificate = 2;
   */
  idpCertificate = "";

  /**
   * @generated from field: string idp_entity_id = 3;
   */
  idpEntityId = "";

  constructor(data?: PartialMessage<ParseSAMLMetadataResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.ParseSAMLMetadataResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "idp_redirect_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "idp_certificate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "idp_entity_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ParseSAMLMetadataResponse {
    return new ParseSAMLMetadataResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ParseSAMLMetadataResponse {
    return new ParseSAMLMetadataResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ParseSAMLMetadataResponse {
    return new ParseSAMLMetadataResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ParseSAMLMetadataResponse | PlainMessage<ParseSAMLMetadataResponse> | undefined, b: ParseSAMLMetadataResponse | PlainMessage<ParseSAMLMetadataResponse> | undefined): boolean {
    return proto3.util.equals(ParseSAMLMetadataResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AppListSCIMDirectoriesRequest
 */
export class AppListSCIMDirectoriesRequest extends Message<AppListSCIMDirectoriesRequest> {
  /**
   * @generated from field: string organization_id = 1;
   */
  organizationId = "";

  /**
   * @generated from field: string page_token = 2;
   */
  pageToken = "";

  constructor(data?: PartialMessage<AppListSCIMDirectoriesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AppListSCIMDirectoriesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "organization_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AppListSCIMDirectoriesRequest {
    return new AppListSCIMDirectoriesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AppListSCIMDirectoriesRequest {
    return new AppListSCIMDirectoriesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AppListSCIMDirectoriesRequest {
    return new AppListSCIMDirectoriesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AppListSCIMDirectoriesRequest | PlainMessage<AppListSCIMDirectoriesRequest> | undefined, b: AppListSCIMDirectoriesRequest | PlainMessage<AppListSCIMDirectoriesRequest> | undefined): boolean {
    return proto3.util.equals(AppListSCIMDirectoriesRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AppListSCIMDirectoriesResponse
 */
export class AppListSCIMDirectoriesResponse extends Message<AppListSCIMDirectoriesResponse> {
  /**
   * @generated from field: repeated ssoready.v1.SCIMDirectory scim_directories = 1;
   */
  scimDirectories: SCIMDirectory[] = [];

  /**
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  constructor(data?: PartialMessage<AppListSCIMDirectoriesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AppListSCIMDirectoriesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "scim_directories", kind: "message", T: SCIMDirectory, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AppListSCIMDirectoriesResponse {
    return new AppListSCIMDirectoriesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AppListSCIMDirectoriesResponse {
    return new AppListSCIMDirectoriesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AppListSCIMDirectoriesResponse {
    return new AppListSCIMDirectoriesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AppListSCIMDirectoriesResponse | PlainMessage<AppListSCIMDirectoriesResponse> | undefined, b: AppListSCIMDirectoriesResponse | PlainMessage<AppListSCIMDirectoriesResponse> | undefined): boolean {
    return proto3.util.equals(AppListSCIMDirectoriesResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AppGetSCIMDirectoryRequest
 */
export class AppGetSCIMDirectoryRequest extends Message<AppGetSCIMDirectoryRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<AppGetSCIMDirectoryRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AppGetSCIMDirectoryRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AppGetSCIMDirectoryRequest {
    return new AppGetSCIMDirectoryRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AppGetSCIMDirectoryRequest {
    return new AppGetSCIMDirectoryRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AppGetSCIMDirectoryRequest {
    return new AppGetSCIMDirectoryRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AppGetSCIMDirectoryRequest | PlainMessage<AppGetSCIMDirectoryRequest> | undefined, b: AppGetSCIMDirectoryRequest | PlainMessage<AppGetSCIMDirectoryRequest> | undefined): boolean {
    return proto3.util.equals(AppGetSCIMDirectoryRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AppCreateSCIMDirectoryRequest
 */
export class AppCreateSCIMDirectoryRequest extends Message<AppCreateSCIMDirectoryRequest> {
  /**
   * @generated from field: ssoready.v1.SCIMDirectory scim_directory = 1;
   */
  scimDirectory?: SCIMDirectory;

  constructor(data?: PartialMessage<AppCreateSCIMDirectoryRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AppCreateSCIMDirectoryRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "scim_directory", kind: "message", T: SCIMDirectory },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AppCreateSCIMDirectoryRequest {
    return new AppCreateSCIMDirectoryRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AppCreateSCIMDirectoryRequest {
    return new AppCreateSCIMDirectoryRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AppCreateSCIMDirectoryRequest {
    return new AppCreateSCIMDirectoryRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AppCreateSCIMDirectoryRequest | PlainMessage<AppCreateSCIMDirectoryRequest> | undefined, b: AppCreateSCIMDirectoryRequest | PlainMessage<AppCreateSCIMDirectoryRequest> | undefined): boolean {
    return proto3.util.equals(AppCreateSCIMDirectoryRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AppUpdateSCIMDirectoryRequest
 */
export class AppUpdateSCIMDirectoryRequest extends Message<AppUpdateSCIMDirectoryRequest> {
  /**
   * @generated from field: ssoready.v1.SCIMDirectory scim_directory = 1;
   */
  scimDirectory?: SCIMDirectory;

  constructor(data?: PartialMessage<AppUpdateSCIMDirectoryRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AppUpdateSCIMDirectoryRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "scim_directory", kind: "message", T: SCIMDirectory },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AppUpdateSCIMDirectoryRequest {
    return new AppUpdateSCIMDirectoryRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AppUpdateSCIMDirectoryRequest {
    return new AppUpdateSCIMDirectoryRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AppUpdateSCIMDirectoryRequest {
    return new AppUpdateSCIMDirectoryRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AppUpdateSCIMDirectoryRequest | PlainMessage<AppUpdateSCIMDirectoryRequest> | undefined, b: AppUpdateSCIMDirectoryRequest | PlainMessage<AppUpdateSCIMDirectoryRequest> | undefined): boolean {
    return proto3.util.equals(AppUpdateSCIMDirectoryRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AppRotateSCIMDirectoryBearerTokenRequest
 */
export class AppRotateSCIMDirectoryBearerTokenRequest extends Message<AppRotateSCIMDirectoryBearerTokenRequest> {
  /**
   * @generated from field: string scim_directory_id = 1;
   */
  scimDirectoryId = "";

  constructor(data?: PartialMessage<AppRotateSCIMDirectoryBearerTokenRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AppRotateSCIMDirectoryBearerTokenRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "scim_directory_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AppRotateSCIMDirectoryBearerTokenRequest {
    return new AppRotateSCIMDirectoryBearerTokenRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AppRotateSCIMDirectoryBearerTokenRequest {
    return new AppRotateSCIMDirectoryBearerTokenRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AppRotateSCIMDirectoryBearerTokenRequest {
    return new AppRotateSCIMDirectoryBearerTokenRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AppRotateSCIMDirectoryBearerTokenRequest | PlainMessage<AppRotateSCIMDirectoryBearerTokenRequest> | undefined, b: AppRotateSCIMDirectoryBearerTokenRequest | PlainMessage<AppRotateSCIMDirectoryBearerTokenRequest> | undefined): boolean {
    return proto3.util.equals(AppRotateSCIMDirectoryBearerTokenRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AppRotateSCIMDirectoryBearerTokenResponse
 */
export class AppRotateSCIMDirectoryBearerTokenResponse extends Message<AppRotateSCIMDirectoryBearerTokenResponse> {
  /**
   * @generated from field: string bearer_token = 1;
   */
  bearerToken = "";

  constructor(data?: PartialMessage<AppRotateSCIMDirectoryBearerTokenResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AppRotateSCIMDirectoryBearerTokenResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "bearer_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AppRotateSCIMDirectoryBearerTokenResponse {
    return new AppRotateSCIMDirectoryBearerTokenResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AppRotateSCIMDirectoryBearerTokenResponse {
    return new AppRotateSCIMDirectoryBearerTokenResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AppRotateSCIMDirectoryBearerTokenResponse {
    return new AppRotateSCIMDirectoryBearerTokenResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AppRotateSCIMDirectoryBearerTokenResponse | PlainMessage<AppRotateSCIMDirectoryBearerTokenResponse> | undefined, b: AppRotateSCIMDirectoryBearerTokenResponse | PlainMessage<AppRotateSCIMDirectoryBearerTokenResponse> | undefined): boolean {
    return proto3.util.equals(AppRotateSCIMDirectoryBearerTokenResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AppListSCIMUsersRequest
 */
export class AppListSCIMUsersRequest extends Message<AppListSCIMUsersRequest> {
  /**
   * @generated from field: string scim_directory_id = 1;
   */
  scimDirectoryId = "";

  /**
   * @generated from field: string page_token = 2;
   */
  pageToken = "";

  /**
   * @generated from field: string scim_group_id = 3;
   */
  scimGroupId = "";

  constructor(data?: PartialMessage<AppListSCIMUsersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AppListSCIMUsersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "scim_directory_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "scim_group_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AppListSCIMUsersRequest {
    return new AppListSCIMUsersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AppListSCIMUsersRequest {
    return new AppListSCIMUsersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AppListSCIMUsersRequest {
    return new AppListSCIMUsersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AppListSCIMUsersRequest | PlainMessage<AppListSCIMUsersRequest> | undefined, b: AppListSCIMUsersRequest | PlainMessage<AppListSCIMUsersRequest> | undefined): boolean {
    return proto3.util.equals(AppListSCIMUsersRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AppListSCIMUsersResponse
 */
export class AppListSCIMUsersResponse extends Message<AppListSCIMUsersResponse> {
  /**
   * @generated from field: repeated ssoready.v1.SCIMUser scim_users = 1;
   */
  scimUsers: SCIMUser[] = [];

  /**
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  constructor(data?: PartialMessage<AppListSCIMUsersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AppListSCIMUsersResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "scim_users", kind: "message", T: SCIMUser, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AppListSCIMUsersResponse {
    return new AppListSCIMUsersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AppListSCIMUsersResponse {
    return new AppListSCIMUsersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AppListSCIMUsersResponse {
    return new AppListSCIMUsersResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AppListSCIMUsersResponse | PlainMessage<AppListSCIMUsersResponse> | undefined, b: AppListSCIMUsersResponse | PlainMessage<AppListSCIMUsersResponse> | undefined): boolean {
    return proto3.util.equals(AppListSCIMUsersResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AppGetSCIMUserRequest
 */
export class AppGetSCIMUserRequest extends Message<AppGetSCIMUserRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<AppGetSCIMUserRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AppGetSCIMUserRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AppGetSCIMUserRequest {
    return new AppGetSCIMUserRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AppGetSCIMUserRequest {
    return new AppGetSCIMUserRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AppGetSCIMUserRequest {
    return new AppGetSCIMUserRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AppGetSCIMUserRequest | PlainMessage<AppGetSCIMUserRequest> | undefined, b: AppGetSCIMUserRequest | PlainMessage<AppGetSCIMUserRequest> | undefined): boolean {
    return proto3.util.equals(AppGetSCIMUserRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AppListSCIMGroupsRequest
 */
export class AppListSCIMGroupsRequest extends Message<AppListSCIMGroupsRequest> {
  /**
   * @generated from field: string scim_directory_id = 1;
   */
  scimDirectoryId = "";

  /**
   * @generated from field: string page_token = 2;
   */
  pageToken = "";

  /**
   * @generated from field: string scim_user_id = 3;
   */
  scimUserId = "";

  constructor(data?: PartialMessage<AppListSCIMGroupsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AppListSCIMGroupsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "scim_directory_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "scim_user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AppListSCIMGroupsRequest {
    return new AppListSCIMGroupsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AppListSCIMGroupsRequest {
    return new AppListSCIMGroupsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AppListSCIMGroupsRequest {
    return new AppListSCIMGroupsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AppListSCIMGroupsRequest | PlainMessage<AppListSCIMGroupsRequest> | undefined, b: AppListSCIMGroupsRequest | PlainMessage<AppListSCIMGroupsRequest> | undefined): boolean {
    return proto3.util.equals(AppListSCIMGroupsRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AppGetSCIMGroupRequest
 */
export class AppGetSCIMGroupRequest extends Message<AppGetSCIMGroupRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<AppGetSCIMGroupRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AppGetSCIMGroupRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AppGetSCIMGroupRequest {
    return new AppGetSCIMGroupRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AppGetSCIMGroupRequest {
    return new AppGetSCIMGroupRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AppGetSCIMGroupRequest {
    return new AppGetSCIMGroupRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AppGetSCIMGroupRequest | PlainMessage<AppGetSCIMGroupRequest> | undefined, b: AppGetSCIMGroupRequest | PlainMessage<AppGetSCIMGroupRequest> | undefined): boolean {
    return proto3.util.equals(AppGetSCIMGroupRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AppListSCIMGroupsResponse
 */
export class AppListSCIMGroupsResponse extends Message<AppListSCIMGroupsResponse> {
  /**
   * @generated from field: repeated ssoready.v1.SCIMGroup scim_groups = 1;
   */
  scimGroups: SCIMGroup[] = [];

  /**
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  constructor(data?: PartialMessage<AppListSCIMGroupsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AppListSCIMGroupsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "scim_groups", kind: "message", T: SCIMGroup, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AppListSCIMGroupsResponse {
    return new AppListSCIMGroupsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AppListSCIMGroupsResponse {
    return new AppListSCIMGroupsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AppListSCIMGroupsResponse {
    return new AppListSCIMGroupsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AppListSCIMGroupsResponse | PlainMessage<AppListSCIMGroupsResponse> | undefined, b: AppListSCIMGroupsResponse | PlainMessage<AppListSCIMGroupsResponse> | undefined): boolean {
    return proto3.util.equals(AppListSCIMGroupsResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AppListSCIMRequestsRequest
 */
export class AppListSCIMRequestsRequest extends Message<AppListSCIMRequestsRequest> {
  /**
   * @generated from field: string scim_directory_id = 1;
   */
  scimDirectoryId = "";

  /**
   * @generated from field: string page_token = 2;
   */
  pageToken = "";

  constructor(data?: PartialMessage<AppListSCIMRequestsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AppListSCIMRequestsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "scim_directory_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AppListSCIMRequestsRequest {
    return new AppListSCIMRequestsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AppListSCIMRequestsRequest {
    return new AppListSCIMRequestsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AppListSCIMRequestsRequest {
    return new AppListSCIMRequestsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AppListSCIMRequestsRequest | PlainMessage<AppListSCIMRequestsRequest> | undefined, b: AppListSCIMRequestsRequest | PlainMessage<AppListSCIMRequestsRequest> | undefined): boolean {
    return proto3.util.equals(AppListSCIMRequestsRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AppListSCIMRequestsResponse
 */
export class AppListSCIMRequestsResponse extends Message<AppListSCIMRequestsResponse> {
  /**
   * @generated from field: repeated ssoready.v1.SCIMRequest scim_requests = 1;
   */
  scimRequests: SCIMRequest[] = [];

  /**
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  constructor(data?: PartialMessage<AppListSCIMRequestsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AppListSCIMRequestsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "scim_requests", kind: "message", T: SCIMRequest, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AppListSCIMRequestsResponse {
    return new AppListSCIMRequestsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AppListSCIMRequestsResponse {
    return new AppListSCIMRequestsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AppListSCIMRequestsResponse {
    return new AppListSCIMRequestsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AppListSCIMRequestsResponse | PlainMessage<AppListSCIMRequestsResponse> | undefined, b: AppListSCIMRequestsResponse | PlainMessage<AppListSCIMRequestsResponse> | undefined): boolean {
    return proto3.util.equals(AppListSCIMRequestsResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AppGetSCIMRequestRequest
 */
export class AppGetSCIMRequestRequest extends Message<AppGetSCIMRequestRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<AppGetSCIMRequestRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AppGetSCIMRequestRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AppGetSCIMRequestRequest {
    return new AppGetSCIMRequestRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AppGetSCIMRequestRequest {
    return new AppGetSCIMRequestRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AppGetSCIMRequestRequest {
    return new AppGetSCIMRequestRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AppGetSCIMRequestRequest | PlainMessage<AppGetSCIMRequestRequest> | undefined, b: AppGetSCIMRequestRequest | PlainMessage<AppGetSCIMRequestRequest> | undefined): boolean {
    return proto3.util.equals(AppGetSCIMRequestRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AppGetSCIMRequestResponse
 */
export class AppGetSCIMRequestResponse extends Message<AppGetSCIMRequestResponse> {
  /**
   * @generated from field: ssoready.v1.SCIMRequest scim_request = 1;
   */
  scimRequest?: SCIMRequest;

  constructor(data?: PartialMessage<AppGetSCIMRequestResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AppGetSCIMRequestResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "scim_request", kind: "message", T: SCIMRequest },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AppGetSCIMRequestResponse {
    return new AppGetSCIMRequestResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AppGetSCIMRequestResponse {
    return new AppGetSCIMRequestResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AppGetSCIMRequestResponse {
    return new AppGetSCIMRequestResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AppGetSCIMRequestResponse | PlainMessage<AppGetSCIMRequestResponse> | undefined, b: AppGetSCIMRequestResponse | PlainMessage<AppGetSCIMRequestResponse> | undefined): boolean {
    return proto3.util.equals(AppGetSCIMRequestResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AdminRedeemOneTimeTokenRequest
 */
export class AdminRedeemOneTimeTokenRequest extends Message<AdminRedeemOneTimeTokenRequest> {
  /**
   * @generated from field: string one_time_token = 1;
   */
  oneTimeToken = "";

  constructor(data?: PartialMessage<AdminRedeemOneTimeTokenRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AdminRedeemOneTimeTokenRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "one_time_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdminRedeemOneTimeTokenRequest {
    return new AdminRedeemOneTimeTokenRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdminRedeemOneTimeTokenRequest {
    return new AdminRedeemOneTimeTokenRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdminRedeemOneTimeTokenRequest {
    return new AdminRedeemOneTimeTokenRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AdminRedeemOneTimeTokenRequest | PlainMessage<AdminRedeemOneTimeTokenRequest> | undefined, b: AdminRedeemOneTimeTokenRequest | PlainMessage<AdminRedeemOneTimeTokenRequest> | undefined): boolean {
    return proto3.util.equals(AdminRedeemOneTimeTokenRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AdminRedeemOneTimeTokenResponse
 */
export class AdminRedeemOneTimeTokenResponse extends Message<AdminRedeemOneTimeTokenResponse> {
  /**
   * @generated from field: string admin_session_token = 1;
   */
  adminSessionToken = "";

  constructor(data?: PartialMessage<AdminRedeemOneTimeTokenResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AdminRedeemOneTimeTokenResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "admin_session_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdminRedeemOneTimeTokenResponse {
    return new AdminRedeemOneTimeTokenResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdminRedeemOneTimeTokenResponse {
    return new AdminRedeemOneTimeTokenResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdminRedeemOneTimeTokenResponse {
    return new AdminRedeemOneTimeTokenResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AdminRedeemOneTimeTokenResponse | PlainMessage<AdminRedeemOneTimeTokenResponse> | undefined, b: AdminRedeemOneTimeTokenResponse | PlainMessage<AdminRedeemOneTimeTokenResponse> | undefined): boolean {
    return proto3.util.equals(AdminRedeemOneTimeTokenResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AdminWhoamiRequest
 */
export class AdminWhoamiRequest extends Message<AdminWhoamiRequest> {
  constructor(data?: PartialMessage<AdminWhoamiRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AdminWhoamiRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdminWhoamiRequest {
    return new AdminWhoamiRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdminWhoamiRequest {
    return new AdminWhoamiRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdminWhoamiRequest {
    return new AdminWhoamiRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AdminWhoamiRequest | PlainMessage<AdminWhoamiRequest> | undefined, b: AdminWhoamiRequest | PlainMessage<AdminWhoamiRequest> | undefined): boolean {
    return proto3.util.equals(AdminWhoamiRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AdminWhoamiResponse
 */
export class AdminWhoamiResponse extends Message<AdminWhoamiResponse> {
  /**
   * @generated from field: bool can_manage_saml = 1;
   */
  canManageSaml = false;

  /**
   * @generated from field: bool can_manage_scim = 2;
   */
  canManageScim = false;

  /**
   * @generated from field: string admin_application_name = 3;
   */
  adminApplicationName = "";

  /**
   * @generated from field: string admin_return_url = 4;
   */
  adminReturnUrl = "";

  /**
   * @generated from field: string admin_logo_url = 5;
   */
  adminLogoUrl = "";

  constructor(data?: PartialMessage<AdminWhoamiResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AdminWhoamiResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "can_manage_saml", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "can_manage_scim", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "admin_application_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "admin_return_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "admin_logo_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdminWhoamiResponse {
    return new AdminWhoamiResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdminWhoamiResponse {
    return new AdminWhoamiResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdminWhoamiResponse {
    return new AdminWhoamiResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AdminWhoamiResponse | PlainMessage<AdminWhoamiResponse> | undefined, b: AdminWhoamiResponse | PlainMessage<AdminWhoamiResponse> | undefined): boolean {
    return proto3.util.equals(AdminWhoamiResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AdminListSAMLConnectionsRequest
 */
export class AdminListSAMLConnectionsRequest extends Message<AdminListSAMLConnectionsRequest> {
  /**
   * @generated from field: string page_token = 1;
   */
  pageToken = "";

  constructor(data?: PartialMessage<AdminListSAMLConnectionsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AdminListSAMLConnectionsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdminListSAMLConnectionsRequest {
    return new AdminListSAMLConnectionsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdminListSAMLConnectionsRequest {
    return new AdminListSAMLConnectionsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdminListSAMLConnectionsRequest {
    return new AdminListSAMLConnectionsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AdminListSAMLConnectionsRequest | PlainMessage<AdminListSAMLConnectionsRequest> | undefined, b: AdminListSAMLConnectionsRequest | PlainMessage<AdminListSAMLConnectionsRequest> | undefined): boolean {
    return proto3.util.equals(AdminListSAMLConnectionsRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AdminListSAMLConnectionsResponse
 */
export class AdminListSAMLConnectionsResponse extends Message<AdminListSAMLConnectionsResponse> {
  /**
   * @generated from field: repeated ssoready.v1.SAMLConnection saml_connections = 1;
   */
  samlConnections: SAMLConnection[] = [];

  /**
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  constructor(data?: PartialMessage<AdminListSAMLConnectionsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AdminListSAMLConnectionsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "saml_connections", kind: "message", T: SAMLConnection, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdminListSAMLConnectionsResponse {
    return new AdminListSAMLConnectionsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdminListSAMLConnectionsResponse {
    return new AdminListSAMLConnectionsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdminListSAMLConnectionsResponse {
    return new AdminListSAMLConnectionsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AdminListSAMLConnectionsResponse | PlainMessage<AdminListSAMLConnectionsResponse> | undefined, b: AdminListSAMLConnectionsResponse | PlainMessage<AdminListSAMLConnectionsResponse> | undefined): boolean {
    return proto3.util.equals(AdminListSAMLConnectionsResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AdminGetSAMLConnectionRequest
 */
export class AdminGetSAMLConnectionRequest extends Message<AdminGetSAMLConnectionRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<AdminGetSAMLConnectionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AdminGetSAMLConnectionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdminGetSAMLConnectionRequest {
    return new AdminGetSAMLConnectionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdminGetSAMLConnectionRequest {
    return new AdminGetSAMLConnectionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdminGetSAMLConnectionRequest {
    return new AdminGetSAMLConnectionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AdminGetSAMLConnectionRequest | PlainMessage<AdminGetSAMLConnectionRequest> | undefined, b: AdminGetSAMLConnectionRequest | PlainMessage<AdminGetSAMLConnectionRequest> | undefined): boolean {
    return proto3.util.equals(AdminGetSAMLConnectionRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AdminGetSAMLConnectionResponse
 */
export class AdminGetSAMLConnectionResponse extends Message<AdminGetSAMLConnectionResponse> {
  /**
   * @generated from field: ssoready.v1.SAMLConnection saml_connection = 1;
   */
  samlConnection?: SAMLConnection;

  constructor(data?: PartialMessage<AdminGetSAMLConnectionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AdminGetSAMLConnectionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "saml_connection", kind: "message", T: SAMLConnection },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdminGetSAMLConnectionResponse {
    return new AdminGetSAMLConnectionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdminGetSAMLConnectionResponse {
    return new AdminGetSAMLConnectionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdminGetSAMLConnectionResponse {
    return new AdminGetSAMLConnectionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AdminGetSAMLConnectionResponse | PlainMessage<AdminGetSAMLConnectionResponse> | undefined, b: AdminGetSAMLConnectionResponse | PlainMessage<AdminGetSAMLConnectionResponse> | undefined): boolean {
    return proto3.util.equals(AdminGetSAMLConnectionResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AdminCreateSAMLConnectionRequest
 */
export class AdminCreateSAMLConnectionRequest extends Message<AdminCreateSAMLConnectionRequest> {
  /**
   * @generated from field: ssoready.v1.SAMLConnection saml_connection = 1;
   */
  samlConnection?: SAMLConnection;

  constructor(data?: PartialMessage<AdminCreateSAMLConnectionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AdminCreateSAMLConnectionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "saml_connection", kind: "message", T: SAMLConnection },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdminCreateSAMLConnectionRequest {
    return new AdminCreateSAMLConnectionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdminCreateSAMLConnectionRequest {
    return new AdminCreateSAMLConnectionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdminCreateSAMLConnectionRequest {
    return new AdminCreateSAMLConnectionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AdminCreateSAMLConnectionRequest | PlainMessage<AdminCreateSAMLConnectionRequest> | undefined, b: AdminCreateSAMLConnectionRequest | PlainMessage<AdminCreateSAMLConnectionRequest> | undefined): boolean {
    return proto3.util.equals(AdminCreateSAMLConnectionRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AdminCreateSAMLConnectionResponse
 */
export class AdminCreateSAMLConnectionResponse extends Message<AdminCreateSAMLConnectionResponse> {
  /**
   * @generated from field: ssoready.v1.SAMLConnection saml_connection = 1;
   */
  samlConnection?: SAMLConnection;

  constructor(data?: PartialMessage<AdminCreateSAMLConnectionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AdminCreateSAMLConnectionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "saml_connection", kind: "message", T: SAMLConnection },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdminCreateSAMLConnectionResponse {
    return new AdminCreateSAMLConnectionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdminCreateSAMLConnectionResponse {
    return new AdminCreateSAMLConnectionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdminCreateSAMLConnectionResponse {
    return new AdminCreateSAMLConnectionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AdminCreateSAMLConnectionResponse | PlainMessage<AdminCreateSAMLConnectionResponse> | undefined, b: AdminCreateSAMLConnectionResponse | PlainMessage<AdminCreateSAMLConnectionResponse> | undefined): boolean {
    return proto3.util.equals(AdminCreateSAMLConnectionResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AdminUpdateSAMLConnectionRequest
 */
export class AdminUpdateSAMLConnectionRequest extends Message<AdminUpdateSAMLConnectionRequest> {
  /**
   * @generated from field: ssoready.v1.SAMLConnection saml_connection = 1;
   */
  samlConnection?: SAMLConnection;

  constructor(data?: PartialMessage<AdminUpdateSAMLConnectionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AdminUpdateSAMLConnectionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "saml_connection", kind: "message", T: SAMLConnection },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdminUpdateSAMLConnectionRequest {
    return new AdminUpdateSAMLConnectionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdminUpdateSAMLConnectionRequest {
    return new AdminUpdateSAMLConnectionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdminUpdateSAMLConnectionRequest {
    return new AdminUpdateSAMLConnectionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AdminUpdateSAMLConnectionRequest | PlainMessage<AdminUpdateSAMLConnectionRequest> | undefined, b: AdminUpdateSAMLConnectionRequest | PlainMessage<AdminUpdateSAMLConnectionRequest> | undefined): boolean {
    return proto3.util.equals(AdminUpdateSAMLConnectionRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AdminUpdateSAMLConnectionResponse
 */
export class AdminUpdateSAMLConnectionResponse extends Message<AdminUpdateSAMLConnectionResponse> {
  /**
   * @generated from field: ssoready.v1.SAMLConnection saml_connection = 1;
   */
  samlConnection?: SAMLConnection;

  constructor(data?: PartialMessage<AdminUpdateSAMLConnectionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AdminUpdateSAMLConnectionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "saml_connection", kind: "message", T: SAMLConnection },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdminUpdateSAMLConnectionResponse {
    return new AdminUpdateSAMLConnectionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdminUpdateSAMLConnectionResponse {
    return new AdminUpdateSAMLConnectionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdminUpdateSAMLConnectionResponse {
    return new AdminUpdateSAMLConnectionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AdminUpdateSAMLConnectionResponse | PlainMessage<AdminUpdateSAMLConnectionResponse> | undefined, b: AdminUpdateSAMLConnectionResponse | PlainMessage<AdminUpdateSAMLConnectionResponse> | undefined): boolean {
    return proto3.util.equals(AdminUpdateSAMLConnectionResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AdminParseSAMLMetadataRequest
 */
export class AdminParseSAMLMetadataRequest extends Message<AdminParseSAMLMetadataRequest> {
  /**
   * @generated from field: string url = 1;
   */
  url = "";

  /**
   * @generated from field: string xml = 2;
   */
  xml = "";

  constructor(data?: PartialMessage<AdminParseSAMLMetadataRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AdminParseSAMLMetadataRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "xml", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdminParseSAMLMetadataRequest {
    return new AdminParseSAMLMetadataRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdminParseSAMLMetadataRequest {
    return new AdminParseSAMLMetadataRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdminParseSAMLMetadataRequest {
    return new AdminParseSAMLMetadataRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AdminParseSAMLMetadataRequest | PlainMessage<AdminParseSAMLMetadataRequest> | undefined, b: AdminParseSAMLMetadataRequest | PlainMessage<AdminParseSAMLMetadataRequest> | undefined): boolean {
    return proto3.util.equals(AdminParseSAMLMetadataRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AdminParseSAMLMetadataResponse
 */
export class AdminParseSAMLMetadataResponse extends Message<AdminParseSAMLMetadataResponse> {
  /**
   * @generated from field: string idp_redirect_url = 1;
   */
  idpRedirectUrl = "";

  /**
   * @generated from field: string idp_certificate = 2;
   */
  idpCertificate = "";

  /**
   * @generated from field: string idp_entity_id = 3;
   */
  idpEntityId = "";

  constructor(data?: PartialMessage<AdminParseSAMLMetadataResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AdminParseSAMLMetadataResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "idp_redirect_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "idp_certificate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "idp_entity_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdminParseSAMLMetadataResponse {
    return new AdminParseSAMLMetadataResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdminParseSAMLMetadataResponse {
    return new AdminParseSAMLMetadataResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdminParseSAMLMetadataResponse {
    return new AdminParseSAMLMetadataResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AdminParseSAMLMetadataResponse | PlainMessage<AdminParseSAMLMetadataResponse> | undefined, b: AdminParseSAMLMetadataResponse | PlainMessage<AdminParseSAMLMetadataResponse> | undefined): boolean {
    return proto3.util.equals(AdminParseSAMLMetadataResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AdminListSAMLFlowsRequest
 */
export class AdminListSAMLFlowsRequest extends Message<AdminListSAMLFlowsRequest> {
  /**
   * @generated from field: string saml_connection_id = 1;
   */
  samlConnectionId = "";

  /**
   * @generated from field: string page_token = 2;
   */
  pageToken = "";

  constructor(data?: PartialMessage<AdminListSAMLFlowsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AdminListSAMLFlowsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "saml_connection_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdminListSAMLFlowsRequest {
    return new AdminListSAMLFlowsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdminListSAMLFlowsRequest {
    return new AdminListSAMLFlowsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdminListSAMLFlowsRequest {
    return new AdminListSAMLFlowsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AdminListSAMLFlowsRequest | PlainMessage<AdminListSAMLFlowsRequest> | undefined, b: AdminListSAMLFlowsRequest | PlainMessage<AdminListSAMLFlowsRequest> | undefined): boolean {
    return proto3.util.equals(AdminListSAMLFlowsRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AdminListSAMLFlowsResponse
 */
export class AdminListSAMLFlowsResponse extends Message<AdminListSAMLFlowsResponse> {
  /**
   * @generated from field: repeated ssoready.v1.SAMLFlow saml_flows = 1;
   */
  samlFlows: SAMLFlow[] = [];

  /**
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  constructor(data?: PartialMessage<AdminListSAMLFlowsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AdminListSAMLFlowsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "saml_flows", kind: "message", T: SAMLFlow, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdminListSAMLFlowsResponse {
    return new AdminListSAMLFlowsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdminListSAMLFlowsResponse {
    return new AdminListSAMLFlowsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdminListSAMLFlowsResponse {
    return new AdminListSAMLFlowsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AdminListSAMLFlowsResponse | PlainMessage<AdminListSAMLFlowsResponse> | undefined, b: AdminListSAMLFlowsResponse | PlainMessage<AdminListSAMLFlowsResponse> | undefined): boolean {
    return proto3.util.equals(AdminListSAMLFlowsResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AdminGetSAMLFlowRequest
 */
export class AdminGetSAMLFlowRequest extends Message<AdminGetSAMLFlowRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<AdminGetSAMLFlowRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AdminGetSAMLFlowRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdminGetSAMLFlowRequest {
    return new AdminGetSAMLFlowRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdminGetSAMLFlowRequest {
    return new AdminGetSAMLFlowRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdminGetSAMLFlowRequest {
    return new AdminGetSAMLFlowRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AdminGetSAMLFlowRequest | PlainMessage<AdminGetSAMLFlowRequest> | undefined, b: AdminGetSAMLFlowRequest | PlainMessage<AdminGetSAMLFlowRequest> | undefined): boolean {
    return proto3.util.equals(AdminGetSAMLFlowRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AdminGetSAMLFlowResponse
 */
export class AdminGetSAMLFlowResponse extends Message<AdminGetSAMLFlowResponse> {
  /**
   * @generated from field: ssoready.v1.SAMLFlow saml_flow = 1;
   */
  samlFlow?: SAMLFlow;

  constructor(data?: PartialMessage<AdminGetSAMLFlowResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AdminGetSAMLFlowResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "saml_flow", kind: "message", T: SAMLFlow },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdminGetSAMLFlowResponse {
    return new AdminGetSAMLFlowResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdminGetSAMLFlowResponse {
    return new AdminGetSAMLFlowResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdminGetSAMLFlowResponse {
    return new AdminGetSAMLFlowResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AdminGetSAMLFlowResponse | PlainMessage<AdminGetSAMLFlowResponse> | undefined, b: AdminGetSAMLFlowResponse | PlainMessage<AdminGetSAMLFlowResponse> | undefined): boolean {
    return proto3.util.equals(AdminGetSAMLFlowResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AdminListSCIMDirectoriesRequest
 */
export class AdminListSCIMDirectoriesRequest extends Message<AdminListSCIMDirectoriesRequest> {
  /**
   * @generated from field: string page_token = 1;
   */
  pageToken = "";

  constructor(data?: PartialMessage<AdminListSCIMDirectoriesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AdminListSCIMDirectoriesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdminListSCIMDirectoriesRequest {
    return new AdminListSCIMDirectoriesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdminListSCIMDirectoriesRequest {
    return new AdminListSCIMDirectoriesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdminListSCIMDirectoriesRequest {
    return new AdminListSCIMDirectoriesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AdminListSCIMDirectoriesRequest | PlainMessage<AdminListSCIMDirectoriesRequest> | undefined, b: AdminListSCIMDirectoriesRequest | PlainMessage<AdminListSCIMDirectoriesRequest> | undefined): boolean {
    return proto3.util.equals(AdminListSCIMDirectoriesRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AdminListSCIMDirectoriesResponse
 */
export class AdminListSCIMDirectoriesResponse extends Message<AdminListSCIMDirectoriesResponse> {
  /**
   * @generated from field: repeated ssoready.v1.SCIMDirectory scim_directories = 1;
   */
  scimDirectories: SCIMDirectory[] = [];

  /**
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  constructor(data?: PartialMessage<AdminListSCIMDirectoriesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AdminListSCIMDirectoriesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "scim_directories", kind: "message", T: SCIMDirectory, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdminListSCIMDirectoriesResponse {
    return new AdminListSCIMDirectoriesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdminListSCIMDirectoriesResponse {
    return new AdminListSCIMDirectoriesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdminListSCIMDirectoriesResponse {
    return new AdminListSCIMDirectoriesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AdminListSCIMDirectoriesResponse | PlainMessage<AdminListSCIMDirectoriesResponse> | undefined, b: AdminListSCIMDirectoriesResponse | PlainMessage<AdminListSCIMDirectoriesResponse> | undefined): boolean {
    return proto3.util.equals(AdminListSCIMDirectoriesResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AdminGetSCIMDirectoryRequest
 */
export class AdminGetSCIMDirectoryRequest extends Message<AdminGetSCIMDirectoryRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<AdminGetSCIMDirectoryRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AdminGetSCIMDirectoryRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdminGetSCIMDirectoryRequest {
    return new AdminGetSCIMDirectoryRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdminGetSCIMDirectoryRequest {
    return new AdminGetSCIMDirectoryRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdminGetSCIMDirectoryRequest {
    return new AdminGetSCIMDirectoryRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AdminGetSCIMDirectoryRequest | PlainMessage<AdminGetSCIMDirectoryRequest> | undefined, b: AdminGetSCIMDirectoryRequest | PlainMessage<AdminGetSCIMDirectoryRequest> | undefined): boolean {
    return proto3.util.equals(AdminGetSCIMDirectoryRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AdminGetSCIMDirectoryResponse
 */
export class AdminGetSCIMDirectoryResponse extends Message<AdminGetSCIMDirectoryResponse> {
  /**
   * @generated from field: ssoready.v1.SCIMDirectory scim_directory = 1;
   */
  scimDirectory?: SCIMDirectory;

  constructor(data?: PartialMessage<AdminGetSCIMDirectoryResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AdminGetSCIMDirectoryResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "scim_directory", kind: "message", T: SCIMDirectory },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdminGetSCIMDirectoryResponse {
    return new AdminGetSCIMDirectoryResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdminGetSCIMDirectoryResponse {
    return new AdminGetSCIMDirectoryResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdminGetSCIMDirectoryResponse {
    return new AdminGetSCIMDirectoryResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AdminGetSCIMDirectoryResponse | PlainMessage<AdminGetSCIMDirectoryResponse> | undefined, b: AdminGetSCIMDirectoryResponse | PlainMessage<AdminGetSCIMDirectoryResponse> | undefined): boolean {
    return proto3.util.equals(AdminGetSCIMDirectoryResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AdminCreateSCIMDirectoryRequest
 */
export class AdminCreateSCIMDirectoryRequest extends Message<AdminCreateSCIMDirectoryRequest> {
  /**
   * @generated from field: ssoready.v1.SCIMDirectory scim_directory = 1;
   */
  scimDirectory?: SCIMDirectory;

  constructor(data?: PartialMessage<AdminCreateSCIMDirectoryRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AdminCreateSCIMDirectoryRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "scim_directory", kind: "message", T: SCIMDirectory },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdminCreateSCIMDirectoryRequest {
    return new AdminCreateSCIMDirectoryRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdminCreateSCIMDirectoryRequest {
    return new AdminCreateSCIMDirectoryRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdminCreateSCIMDirectoryRequest {
    return new AdminCreateSCIMDirectoryRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AdminCreateSCIMDirectoryRequest | PlainMessage<AdminCreateSCIMDirectoryRequest> | undefined, b: AdminCreateSCIMDirectoryRequest | PlainMessage<AdminCreateSCIMDirectoryRequest> | undefined): boolean {
    return proto3.util.equals(AdminCreateSCIMDirectoryRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AdminCreateSCIMDirectoryResponse
 */
export class AdminCreateSCIMDirectoryResponse extends Message<AdminCreateSCIMDirectoryResponse> {
  /**
   * @generated from field: ssoready.v1.SCIMDirectory scim_directory = 1;
   */
  scimDirectory?: SCIMDirectory;

  constructor(data?: PartialMessage<AdminCreateSCIMDirectoryResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AdminCreateSCIMDirectoryResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "scim_directory", kind: "message", T: SCIMDirectory },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdminCreateSCIMDirectoryResponse {
    return new AdminCreateSCIMDirectoryResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdminCreateSCIMDirectoryResponse {
    return new AdminCreateSCIMDirectoryResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdminCreateSCIMDirectoryResponse {
    return new AdminCreateSCIMDirectoryResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AdminCreateSCIMDirectoryResponse | PlainMessage<AdminCreateSCIMDirectoryResponse> | undefined, b: AdminCreateSCIMDirectoryResponse | PlainMessage<AdminCreateSCIMDirectoryResponse> | undefined): boolean {
    return proto3.util.equals(AdminCreateSCIMDirectoryResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AdminUpdateSCIMDirectoryRequest
 */
export class AdminUpdateSCIMDirectoryRequest extends Message<AdminUpdateSCIMDirectoryRequest> {
  /**
   * @generated from field: ssoready.v1.SCIMDirectory scim_directory = 1;
   */
  scimDirectory?: SCIMDirectory;

  constructor(data?: PartialMessage<AdminUpdateSCIMDirectoryRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AdminUpdateSCIMDirectoryRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "scim_directory", kind: "message", T: SCIMDirectory },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdminUpdateSCIMDirectoryRequest {
    return new AdminUpdateSCIMDirectoryRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdminUpdateSCIMDirectoryRequest {
    return new AdminUpdateSCIMDirectoryRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdminUpdateSCIMDirectoryRequest {
    return new AdminUpdateSCIMDirectoryRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AdminUpdateSCIMDirectoryRequest | PlainMessage<AdminUpdateSCIMDirectoryRequest> | undefined, b: AdminUpdateSCIMDirectoryRequest | PlainMessage<AdminUpdateSCIMDirectoryRequest> | undefined): boolean {
    return proto3.util.equals(AdminUpdateSCIMDirectoryRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AdminUpdateSCIMDirectoryResponse
 */
export class AdminUpdateSCIMDirectoryResponse extends Message<AdminUpdateSCIMDirectoryResponse> {
  /**
   * @generated from field: ssoready.v1.SCIMDirectory scim_directory = 1;
   */
  scimDirectory?: SCIMDirectory;

  constructor(data?: PartialMessage<AdminUpdateSCIMDirectoryResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AdminUpdateSCIMDirectoryResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "scim_directory", kind: "message", T: SCIMDirectory },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdminUpdateSCIMDirectoryResponse {
    return new AdminUpdateSCIMDirectoryResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdminUpdateSCIMDirectoryResponse {
    return new AdminUpdateSCIMDirectoryResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdminUpdateSCIMDirectoryResponse {
    return new AdminUpdateSCIMDirectoryResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AdminUpdateSCIMDirectoryResponse | PlainMessage<AdminUpdateSCIMDirectoryResponse> | undefined, b: AdminUpdateSCIMDirectoryResponse | PlainMessage<AdminUpdateSCIMDirectoryResponse> | undefined): boolean {
    return proto3.util.equals(AdminUpdateSCIMDirectoryResponse, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AdminRotateSCIMDirectoryBearerTokenRequest
 */
export class AdminRotateSCIMDirectoryBearerTokenRequest extends Message<AdminRotateSCIMDirectoryBearerTokenRequest> {
  /**
   * @generated from field: string scim_directory_id = 1;
   */
  scimDirectoryId = "";

  constructor(data?: PartialMessage<AdminRotateSCIMDirectoryBearerTokenRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AdminRotateSCIMDirectoryBearerTokenRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "scim_directory_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdminRotateSCIMDirectoryBearerTokenRequest {
    return new AdminRotateSCIMDirectoryBearerTokenRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdminRotateSCIMDirectoryBearerTokenRequest {
    return new AdminRotateSCIMDirectoryBearerTokenRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdminRotateSCIMDirectoryBearerTokenRequest {
    return new AdminRotateSCIMDirectoryBearerTokenRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AdminRotateSCIMDirectoryBearerTokenRequest | PlainMessage<AdminRotateSCIMDirectoryBearerTokenRequest> | undefined, b: AdminRotateSCIMDirectoryBearerTokenRequest | PlainMessage<AdminRotateSCIMDirectoryBearerTokenRequest> | undefined): boolean {
    return proto3.util.equals(AdminRotateSCIMDirectoryBearerTokenRequest, a, b);
  }
}

/**
 * @generated from message ssoready.v1.AdminRotateSCIMDirectoryBearerTokenResponse
 */
export class AdminRotateSCIMDirectoryBearerTokenResponse extends Message<AdminRotateSCIMDirectoryBearerTokenResponse> {
  /**
   * @generated from field: string bearer_token = 1;
   */
  bearerToken = "";

  constructor(data?: PartialMessage<AdminRotateSCIMDirectoryBearerTokenResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ssoready.v1.AdminRotateSCIMDirectoryBearerTokenResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "bearer_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdminRotateSCIMDirectoryBearerTokenResponse {
    return new AdminRotateSCIMDirectoryBearerTokenResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdminRotateSCIMDirectoryBearerTokenResponse {
    return new AdminRotateSCIMDirectoryBearerTokenResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdminRotateSCIMDirectoryBearerTokenResponse {
    return new AdminRotateSCIMDirectoryBearerTokenResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AdminRotateSCIMDirectoryBearerTokenResponse | PlainMessage<AdminRotateSCIMDirectoryBearerTokenResponse> | undefined, b: AdminRotateSCIMDirectoryBearerTokenResponse | PlainMessage<AdminRotateSCIMDirectoryBearerTokenResponse> | undefined): boolean {
    return proto3.util.equals(AdminRotateSCIMDirectoryBearerTokenResponse, a, b);
  }
}

